import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { ISubject, IFilterSelected } from "../../models";
import ProgressBar from "../../../../../../../../common/components/ProgressBar/ProgressBar";
import { useGenerateStringFilter } from "../../utils";
import css from "./subjectListView.module.scss";

interface ISubjectListItemProps {
  unit: ISubject;
  routes?: any;
  filterSelected?: IFilterSelected;
}

const SubjectListItem: React.FunctionComponent<ISubjectListItemProps> = (
  props: ISubjectListItemProps,
) => {
  const { unit, routes, filterSelected } = props;
  const { department, program, curriculum } = filterSelected;
  const stringFilter = useGenerateStringFilter(department, program, curriculum, "1");
  const history = useHistory();

  const handleClick = () => {
    history.push(`${routes.unit(unit.unit.id)}${stringFilter}`);
  };

  return (
    <ul className={cx(css.cntRowList, "container-row")} onClick={handleClick}>
      <li className={cx(css.cntRowList_item, css.cntRowList_item__progressBar)}>
        <ProgressBar progress={unit.unit.progress} />
      </li>
      <li className={cx(css.cntRowList_item, "col_3")}>{unit.subject}</li>
      <li className={cx(css.cntRowList_item, "col_4")}>{unit.unit.label}</li>
    </ul>
  );
};

export default SubjectListItem;
