import React from "react";
import { Popup } from "@foris/foris-ui";
import cx from "classnames";
import catalogos from "./../../../../assets/menuForisIcons/catalogos.svg";
import proyecciones from "./../../../../assets/menuForisIcons/proyecciones.svg";
import asignaciones from "./../../../../assets/menuForisIcons/asignaciones.svg";
import horarios from "./../../../../assets/menuForisIcons/analytics.svg";
import edd from "./../../../../assets/menuForisIcons/edd.svg";
import edh from "./../../../../assets/menuForisIcons/edh.svg";
import espacios from "./../../../../assets/menuForisIcons/espacios.svg";
// import analytics from "./../../../../assets/menuForisIcons/analytics.svg";
import css from "./dropdown-menu.module.scss";
import Icon from "../../Icon/Icon";

interface IModules {
  title: string;
  icon: any;
  order: number;
}

const DropDownMenu = () => {
  const firstModules: IModules[] = [
    {
      icon: catalogos,
      title: "Catálogos",
      order: 1,
    },
    {
      icon: proyecciones,
      title: "Proyecciones",
      order: 2,
    },
    {
      icon: asignaciones,
      title: "Asignaciones",
      order: 3,
    },
    {
      icon: horarios,
      title: "Horarios",
      order: 4,
    },
  ];

  const secondModules: IModules[] = [
    {
      icon: edh,
      title: "Editor de horarios",
      order: 2,
    },
    {
      icon: edd,
      title: "Editor de demanda",
      order: 1,
    },
    {
      icon: espacios,
      title: "Reserva de espacios",
      order: 3,
    },
  ];

  const orderFirst =
    firstModules.length > 0 ? firstModules.sort((a, b) => a.order - b.order) : null;
  const orderSecond =
    secondModules.length > 0 ? secondModules.sort((a, b) => a.order - b.order) : null;

  return (
    <Popup
      className={cx("right", css.menuProducts)}
      trigger={
        <div>
          <Icon icon="cube" className={css.menuProducts_icon} />
        </div>
      }
      flowing
      on="click"
    >
      {orderFirst.length > 0 && (
        <section className={cx(css.cntProducts, "container-row")}>
          <p className={cx(css.cntProducts_title, "col_12")}>Módulos de optimización</p>
          {orderFirst.map((value, index) => {
            return (
              <figure key={index} className={cx(css.cntProducts_item)}>
                <img className={cx(css.logo)} src={value.icon} />
                <p className={cx(css.nameProduct, "col_12")}>{value.title}</p>
              </figure>
            );
          })}
        </section>
      )}
      {orderSecond.length > 0 && (
        <section className={cx(css.cntProducts, "container-row")}>
          <p className={cx(css.cntProducts_title, "col_12")}>Módulos de gestión</p>
          {secondModules.map((value, index) => {
            return (
              <figure key={index} className={cx(css.cntProducts_item)}>
                <img className={cx(css.logo)} src={value.icon} />
                <p className={cx(css.nameProduct, "col_12")}>{value.title}</p>
              </figure>
            );
          })}
        </section>
      )}
    </Popup>
  );
};

export default DropDownMenu;
