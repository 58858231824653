import React from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import { Button, Loader, Icon } from "@foris/foris-ui";
import { BreadCrumb } from "../../../../../../common";
import { DEFAULT_MESSAGES } from "../../../../../../common/config";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import { Filters, UnitsResult } from "./components";
import { GET_FILTERS_DATA } from "./queries";

const breadCrumbItems = [{ content: "Editor de demanda", link: false }];

const Container = (props: any) => {
  return (
    <div className="row">
      <div className="col">
        <BreadCrumb items={breadCrumbItems} />
        {/* <h3 style={{ margin: "0px 0px 1.5em 0px", padding: "0px" }}>
          Test-41 <span style={{ fontWeight: 400 }}>(BASE / UPCH / Origen: 525)</span>
        </h3> */}
        {props.context.auth.role.permissions &&
          props.context.auth.role.permissions.crosslist.includes("create") && (
            <Link
              to={props.context.routes.crosslistAdd()}
              style={{ marginBottom: "1.5em", display: "inline-block" }}
            >
              <Button basic icon labelPosition="left" style={{ backgroundColor: "white" }}>
                <Icon name="plus" /> Crear lista cruzada
              </Button>
            </Link>
          )}
        {props.content}
      </div>
    </div>
  );
};

const Dashboard = ({ forecastContext }: { forecastContext?: IForecastContextInterface }) =>
  forecastContext && (
    <Query
      query={GET_FILTERS_DATA}
      variables={{ projectId: forecastContext.routes.context.project }}
    >
      {({ loading, error, data }: any) => {
        if (loading)
          return (
            <Container
              context={forecastContext}
              content={
                <div style={{ minHeight: "200px" }}>
                  <Loader active />
                </div>
              }
            />
          );
        if (error || data.status.code !== 200)
          return <Container context={forecastContext} content={<p>{DEFAULT_MESSAGES.ERROR}</p>} />;
        if (data.project) {
          return (
            <Container
              context={forecastContext}
              content={
                <>
                  <Filters
                    {...data}
                    context={forecastContext}
                    projectId={forecastContext.routes.context.project}
                  />
                  <UnitsResult
                    {...forecastContext}
                    projectId={forecastContext.routes.context.project}
                  />
                </>
              }
            />
          );
        } else {
          return <Container context={forecastContext} content={<p>{DEFAULT_MESSAGES.ERROR}</p>} />;
        }
      }}
    </Query>
  );

export default withForecastContext(Dashboard);
