import { useMutation, useApolloClient } from "react-apollo";
import editDemand from "./editDemand";
import { GET_UNITS } from "../../dashboard/queries";
import { DemandAdjustment } from "../../../../../../../models/ISchema";

type InputEditDemand = {
  unitId: string;
  demandAdjustments: DemandAdjustment[];
};

export const useEditDemand = () => {
  const client = useApolloClient();
  const [editDemandMutation] = useMutation(editDemand, {});

  const updateCacheData = async (context: any) => {
    const dashboard = context.dashboard;
    const department = dashboard.currentDepartmentListSelected?.value;
    const program = dashboard.currentProgramListSelected?.value;
    const curriculum = dashboard.currentCurriculumListSelected?.value;
    const filters: any = {
      departmentId: department,
      programId: program === "*" ? null : program,
      curriculumId: curriculum === "*" ? null : curriculum,
    };
    if (!filters.departmentId && !filters.programId && !filters.curriculumId) {
      return false;
    } else {
      try {
        const routes = context.routes;
        const cacheObj: any = await client.cache.readQuery({
          query: GET_UNITS,
          variables: {
            projectId: routes.context.project,
            filter: filters,
          },
        });
        context.updateDashboardCurrentData("unitView", cacheObj.project.dashboard);
      } catch (e) {
        console.log("error: >", e);
      }
    }
  };

  const editDemandFunc = async (variables: InputEditDemand, context: any) => {
    try {
      const response = await editDemandMutation({ variables });
      await updateCacheData(context);
      return { status: true, data: response };
    } catch (e) {
      console.error(e);
      return { status: false, data: null };
    }
  };

  return {
    editDemand: editDemandFunc,
  };
};

export default useEditDemand;
