import React, { useState } from "react";
import DownloadReportModal from "./DownloadReportModal";
import { useApolloClient } from "react-apollo";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Icon from "./../../../components/Icon/Icon";
import gql from "graphql-tag";
import "./menu.scss";

export interface IChange {
  isOpen: boolean;
}

const GET_REPORT = gql`
  mutation GET_REPORT($projectId: ID!, $type: ReportType!) {
    report(projectId: $projectId, type: $type) {
      type
      url
    }
  }
`;

const MenuHead = (props: any) => {
  const [open, setOpen] = useState(false);
  const [modalFileConfirmation, setModalFileConfirmation] = useState(false);
  const [linkReadyToDownload, setLinkReadyToDownload] = useState(false);
  const [downloadModalTitle, setDownloadModalTitle] = useState("");
  const [reportLink, setReportLink] = useState("#");
  const [reportResponseError, setReportResponseError] = useState(false);

  const handleStateChange = (state: IChange) => {
    setOpen(state.isOpen);
  };

  const client = useApolloClient();

  return (
    <>
      <Menu
        onStateChange={(state: IChange) => handleStateChange(state)}
        isOpen={open}
        className="bm-menu"
        pageWrapId="header"
        outerContainerId="root"
        customBurgerIcon={<Icon icon="menu" className="iconMenu" />}
      >
        <Link to={props.routes.projectList()}>
          <div
            className="menu-item"
            onClick={() => {
              setOpen(false);
            }}
          >
            Administrar Proyecto
          </div>
        </Link>
        <main id="page-wrap">
          <hr />
          <strong>Descargables:</strong>
        </main>
        <a href="#" target="_blank">
          <div
            className="menu-item"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              setLinkReadyToDownload(false);
              setReportLink("#");
              setReportResponseError(false);
              setDownloadModalTitle("Status de confirmación");
              setModalFileConfirmation(true);
              client
                .mutate({
                  mutation: GET_REPORT,
                  variables: {
                    projectId: props.routes.context.project,
                    type: "DEMAND_ADJUSTMENT",
                  },
                })
                .then((response: any) => {
                  setLinkReadyToDownload(true);
                  setReportLink(response.data.report.url);
                })
                .catch((error: any) => {
                  console.log(error);
                  setReportResponseError(true);
                });
            }}
          >
            Estatus de confirmación
          </div>
        </a>
        <a href="#" target="_blank">
          <div
            className="menu-item"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              setLinkReadyToDownload(false);
              setReportLink("#");
              setReportResponseError(false);
              setDownloadModalTitle("Análisis de demanda");
              setModalFileConfirmation(true);
              client
                .mutate({
                  mutation: GET_REPORT,
                  variables: {
                    projectId: props.routes.context.project,
                    type: "FORECAST_STATISTICS",
                  },
                })
                .then((response: any) => {
                  setLinkReadyToDownload(true);
                  setReportLink(response.data.report.url);
                })
                .catch((error: any) => {
                  console.log(error);
                  setReportResponseError(true);
                });
            }}
          >
            Análisis de demanda
          </div>
        </a>
        <a href="#" target="_blank">
          <div
            className="menu-item"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();

              setLinkReadyToDownload(false);
              setReportLink("#");
              setReportResponseError(false);
              setDownloadModalTitle("Análisis de demanda");

              setModalFileConfirmation(true);
              client
                .mutate({
                  mutation: GET_REPORT,
                  variables: {
                    projectId: props.routes.context.project,
                    type: "ENROLLMENTS_ANALYSIS",
                  },
                })
                .then((response: any) => {
                  setLinkReadyToDownload(true);
                  setReportLink(response.data.report.url);
                })
                .catch((error: any) => {
                  console.log(error);
                  setReportResponseError(true);
                });
            }}
          >
            Análisis de inscripción
          </div>
        </a>

        <footer className="footer_menu container-row">
          <Link
            to={`/login`}
            className="footer_menu-text col_12"
            onClick={e => {
              e.preventDefault();
              localStorage.clear();
              window.location.href = `/logout?redirect=${props.logoutUrl}`;
            }}
          >
            <Icon className="log_out" icon="log-out" />
            Cerrar Sesión
          </Link>
        </footer>
      </Menu>

      <DownloadReportModal
        open={modalFileConfirmation}
        title={downloadModalTitle}
        reportResponseError={reportResponseError}
        reportLink={reportLink}
        linkReadyToDownload={linkReadyToDownload}
        setModalFileConfirmation={setModalFileConfirmation}
      />
    </>
  );
};

export default MenuHead;
