import * as React from "react";
import icons from "../../../assets/icons/icons.js";
interface IIconProps {
  icon: string;
  className: string;
}
const Icon = (props: IIconProps) => {
  const { icon, className } = props;
  const iconSelect = icon && icons.icons.find((item: any) => item.properties.name === icon);
  return (
    <svg
      className={className}
      style={{ display: "inline-block", stroke: "currentcolor", fill: "currentcolor" }}
      viewBox="0 0 1024 1024"
    >
      {iconSelect &&
        iconSelect.icon.paths.map((item: any, indexIcon: number) => (
          <path key={indexIcon} d={item} />
        ))}
    </svg>
  );
};
export default Icon;
