import React, { useState } from "react";
import { Query, Mutation } from "react-apollo";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Form, Input, TextArea, Button, Loader, Divider } from "@foris/foris-ui";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import { BreadCrumb } from "../../../../../../common";

import { GET_PROJECT_BY_ID_QUERY, EDIT_PROJECT_MUTATION } from "./queries";
import { Slide, toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";

const EditForm: React.FunctionComponent<RouteComponentProps<{}> & { context: any }> = ({
  history,
  match,
  context,
}: any) => {
  const projectId = match.params.projectId;

  const [formValues, setFormValues] = useState({
    isSubmitted: false,
    values: {
      title: { required: true, value: "", isValid: false },
      startDate: { required: true, value: null, isValid: false },
      endDate: { required: true, value: null, isValid: false },
      description: { required: false, value: "", isValid: false },
    },
  });

  const [isLoading, setIsloading] = useState(false);
  const routes = context.routes;
  const breadCrumbItems = [
    { content: "Proyectos", link: true, to: routes.projectList() },
    { content: "Editar proyecto", link: false },
  ];

  const handleChange = (
    e: React.SyntheticEvent,
    { name, value }: { name: string; value: string },
  ) => {
    e.preventDefault();

    const currentFormValue: any = formValues;
    setFormValues({
      ...currentFormValue,
      values: {
        ...currentFormValue.values,
        [name]: { ...currentFormValue.values[name], value: value, isValid: value ? true : false },
      },
    });
  };

  const formWithErrors = () => {
    setFormValues({ ...formValues, isSubmitted: true });
    const obj: any = formValues.values;
    const hasError = Object.keys(obj).filter(
      (key: string) => obj[key].required && obj[key].value === "",
    );
    return !!hasError.length;
  };

  return (
    <Mutation
      mutation={EDIT_PROJECT_MUTATION}
      onCompleted={() => {
        setIsloading(false);
        history.push(routes.projectList());
        toast.info("El proyecto se ha actualizado correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          transition: Slide,
        });
      }}
    >
      {(updateProject: any) => (
        <>
          <BreadCrumb items={breadCrumbItems} />
          <div className="row">
            <div className="col">
              <Form>
                <div style={{ backgroundColor: "white", padding: "2em 3em", marginBottom: "4em" }}>
                  <h3>Editar proyecto</h3> <br />
                  <div className="col-md-6" style={{ padding: "0px" }}>
                    <Query
                      query={GET_PROJECT_BY_ID_QUERY}
                      variables={{ projectId: projectId }}
                      fetchPolicy="network-only"
                      onCompleted={(data: any) => {
                        if (data && data.project) {
                          const currentFormValue: any = formValues;
                          setFormValues({
                            ...currentFormValue,
                            values: {
                              title: {
                                required: true,
                                value: data.project.title || "",
                                isValid: data.project.title ? true : false,
                              },
                              startDate: {
                                required: true,
                                value: data.project.startDate || null,
                                isValid: data.project.startDate ? true : false,
                              },
                              endDate: {
                                required: true,
                                value: data.project.endDate || null,
                                isValid: data.project.endDate ? true : false,
                              },
                              description: {
                                required: false,
                                value: data.project.description || "",
                                isValid: data.project.description ? true : false,
                              },
                            },
                          });
                        }
                      }}
                    >
                      {({ loading, error, data }: any): any => {
                        if (loading)
                          return (
                            <div style={{ display: "block", overflow: "hidden", minHeight: "4em" }}>
                              <Loader active content="Cargando..." />
                            </div>
                          );
                        if (error) return `Error! ${error.message}`;

                        const startDate =
                          formValues.values.startDate.value || data.project.startDate;
                        const endDate = formValues.values.endDate.value || data.project.endDate;
                        const description =
                          formValues.values.description.value || data.project.description;

                        return (
                          <>
                            <Form.Group widths="equal">
                              <Form.Field>
                                <label>Ambiente</label> {data.project.access.workspaceName}
                              </Form.Field>
                              <Form.Field>
                                <label>Escenario</label> {data.project.access.scenarioName}
                              </Form.Field>
                              <Form.Field>
                                <label>Origen</label> {data.project.access.originName}
                              </Form.Field>
                            </Form.Group>
                            <Divider section />
                            <Form.Field
                              control={Input}
                              label="Título"
                              width={8}
                              placeholder="Ingresa un título"
                              defaultValue={formValues.values.title.value}
                              error={
                                formValues.isSubmitted &&
                                formValues.values.title.required &&
                                !formValues.values.title.isValid
                              }
                              name="title"
                              onChange={handleChange}
                            />

                            <Form.Group>
                              <Form.Field
                                width={4}
                                control={DatePicker}
                                locale={es}
                                label="Apertura"
                                placeholderText="Apertura"
                                selected={new Date(`${startDate}T00:00:00`)}
                                dateFormat="dd.MM.yyyy"
                                error={
                                  formValues.isSubmitted &&
                                  formValues.values.startDate.required &&
                                  !formValues.values.startDate.isValid
                                }
                                onChange={(date: Date, e: React.SyntheticEvent) => {
                                  if (date) {
                                    handleChange(e, {
                                      name: "startDate",
                                      value: date.toISOString().split("T")[0],
                                    });
                                  }
                                }}
                              />

                              <Form.Field
                                width={4}
                                control={DatePicker}
                                locale={es}
                                label="Cierre"
                                placeholderText="Cierre"
                                selected={new Date(`${endDate}T00:00:00`)}
                                dateFormat="dd.MM.yyyy"
                                error={
                                  formValues.isSubmitted &&
                                  formValues.values.endDate.required &&
                                  !formValues.values.endDate.isValid
                                }
                                onChange={(date: Date, e: React.SyntheticEvent) => {
                                  if (date) {
                                    handleChange(e, {
                                      name: "endDate",
                                      value: date.toISOString().split("T")[0],
                                    });
                                  }
                                }}
                              />
                            </Form.Group>

                            <Form.Field
                              control={TextArea}
                              label="Descripción"
                              placeholder="Ingresa una descripción"
                              defaultValue={description}
                              error={
                                formValues.isSubmitted &&
                                formValues.values.description.required &&
                                !formValues.values.description.isValid
                              }
                              name="description"
                              onChange={handleChange}
                            />
                            <br />
                            <div className="d-flex align-items-end" style={{ textAlign: "right" }}>
                              <Button
                                className="p-2"
                                style={{
                                  display: "inline-block",
                                  cursor: "pointer",
                                  marginRight: "1em",
                                }}
                                onClick={() => {
                                  history.push(routes.projectList());
                                }}
                                content="Cancelar"
                              />
                              <Button
                                className="p-2"
                                style={{ display: "inline-block", cursor: "pointer" }}
                                type="submit"
                                primary
                                content="Actualizar"
                                loading={isLoading}
                                onClick={(e: React.SyntheticEvent) => {
                                  e.preventDefault();

                                  if (formWithErrors()) {
                                    return false;
                                  } else {
                                    setIsloading(true);
                                    updateProject({
                                      variables: {
                                        projectId: data.project.id,
                                        projectInput: {
                                          access: {
                                            originId: data.project.access.originId,
                                            originName: data.project.access.originName,
                                            scenarioId: data.project.access.scenarioId,
                                            scenarioName: data.project.access.scenarioName,
                                            workspaceId: data.project.access.workspaceId,
                                            workspaceName: data.project.access.workspaceName,
                                          },
                                          title: formValues.values.title.value,
                                          institutionId: context.auth.project.institutionId,
                                          startDate: formValues.values.startDate.value,
                                          endDate: formValues.values.endDate.value,
                                          description: formValues.values.description.value,
                                        },
                                      },
                                    });
                                  }
                                }}
                              />
                            </div>
                          </>
                        );
                      }}
                    </Query>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Mutation>
  );
};

const EditFormWithRouter = withRouter(EditForm);

const EditProjectPageContainer = ({
  forecastContext,
}: {
  forecastContext?: IForecastContextInterface;
}) => forecastContext && <EditFormWithRouter context={forecastContext} />;

export default withForecastContext(EditProjectPageContainer);
