import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import Icon from "../../../../../../../../common/components/Icon/Icon";
import { ICrossList, IFilterSelected } from "../../models";
import Forecast from "../../../../../../../../routes/Forecast";
import ProgressBar from "../../../../../../../../common/components/ProgressBar/ProgressBar";
import { useGenerateStringFilter } from "../../utils";
import css from "./crossListView.module.scss";

interface ICrossListItemProps {
  unit: ICrossList;
  routes: Forecast;
  filterSelected?: IFilterSelected;
}

const CrossListItem: React.FunctionComponent<ICrossListItemProps> = (
  props: ICrossListItemProps,
) => {
  const { unit, routes, filterSelected } = props;
  const { department, program, curriculum } = filterSelected;
  const stringFilter = useGenerateStringFilter(department, program, curriculum, "2");
  const history = useHistory();

  const handleClick = () => {
    history.push(`${routes.unit(unit.unit.id)}${stringFilter}`);
  };

  return (
    <ul className={cx(css.cntRowList, "container-row")} onClick={handleClick}>
      <li className={cx(css.cntRowList_item, css.cntRowList_item__progressBar)}>
        <ProgressBar progress={unit.unit.progress} />
      </li>
      <li className={cx(css.cntRowList_item)}>{unit.unit.label}</li>
      <li className={cx(css.cntRowList_item)}>{unit.unit.description}</li>
      <li className={cx(css.cntRowList_item)}>{unit?.unit?.totalAdjusted}</li>
      <li className={cx(css.cntRowList_item)}>
        {unit?.unit?.crosslistConfirmation ? (
          <Icon icon="check" className={cx(css.cntRowList_item__icon)} />
        ) : (
          ""
        )}
      </li>
    </ul>
  );
};

export default CrossListItem;
