import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import css from "./table.module.scss";

interface IInputCell {
  value: string;
  disabled?: boolean;
  maxLength?: number;
  onChange: (value: string) => void;
  onKeyDown?: (e: any) => void;
}

const InputCell = ({
  value: initialValue,
  disabled,
  onChange,
  onKeyDown,
  maxLength,
}: IInputCell) => {
  const [value, setValue] = useState(initialValue);

  const handleOnChange = (e: any) => {
    setValue(e.target.value.replace(/\D/, ""));
  };

  const onBlur = (e: any) => {
    onChange(e.target.value.replace(/\D/, ""));
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (disabled) return <span>{value}</span>;

  return (
    <Input
      value={value}
      onChange={handleOnChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      className={css.inputCell}
      maxLength={maxLength}
    />
  );
};

export default InputCell;
