import * as React from "react";
import cx from "classnames";
import css from "./progressBar.module.scss";

interface IProgressProps {
  progress: number;
}
const ProgressBar = (props: IProgressProps) => {
  const { progress } = props;
  const percent = Math.round(progress * 100);
  const round = `${Math.ceil(percent / 10) * 10}%`;

  return (
    <div className={cx(css.cntProgress, "container-row")}>
      <div
        className={cx(
          css.cntProgress_percent,
          "container-row",
          percent === 100 && css.cntProgress_percent__full,
        )}
        style={{ width: round }}
      ></div>
    </div>
  );
};
export default ProgressBar;
