import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import Forecast from "../../../../../../../../routes/Forecast";
import { ILevel, IFilterSelected } from "../../models";
import { useGenerateStringFilter } from "../../utils";
import ProgressBar from "../../../../../../../../common/components/ProgressBar/ProgressBar";
import css from "./levelView.module.scss";

interface ILevelProps {
  level: ILevel;
  className?: string;
  routes: Forecast;
  filterSelected?: IFilterSelected;
}

const LevelItem: React.FunctionComponent<ILevelProps> = (props: ILevelProps) => {
  const { level, className, filterSelected } = props;
  const { department, program, curriculum } = filterSelected;
  const stringFilter = useGenerateStringFilter(department, program, curriculum, "0");
  return (
    <Link
      to={`${props.routes.unit(level.unit.id)}${stringFilter}`}
      className={cx(css.cntLevelProgress, className, "col_2")}
    >
      <span className={css.cntLevelProgress_level}>{level.level}</span>
      <ProgressBar progress={level.unit.progress} />
    </Link>
  );
};

export default LevelItem;
