import gql from "graphql-tag";

export const GET_WORKSPACES = gql`
  query contextualizador {
    workspaces {
      id
      name
    }
  }
`;

export const GET_SCENARIOS = gql`
  query scenariosQuery($workspaceId: ID!) {
    scenarios(workspaceId: $workspaceId) {
      id
      name
      description
    }
  }
`;

export const GET_PROJECTS = gql`
  query projectsQuery($institutionId: ID!, $filter: ProjectInput) {
    projects(institutionId: $institutionId, filter: $filter) {
      id
      description
      title
      access {
        scenarioId
        scenarioName
        originId
        originName
      }
    }
  }
`;
