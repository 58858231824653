import React, { useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { Link } from "react-router-dom";
import useEditDemand from "../graphql/useEditDemand";
import EDIT_CONFIRM_CROSSLIST from "../graphql/editConfirmCrosslist";
import cx from "classnames";
import { IForecastContextInterface } from "../../../../../context";
import Table, { CheckboxCell, InputCell } from "../../../../../../../common/components/Table";
import HeaderDemand from "../HeaderDemand/HeaderDemand";
import Paginator from "../../../../../../../common/components/Paginator/Paginator";
import Icon from "../../../../../../../common/components/Icon/Icon";
import Checkbox from "../../../../../../../common/components/Checkbox/Checkbox";
import { DemandAdjustment } from "../../../../../../../models/ISchema";
import fakeAuth from "../../../../../../../authClient";
import { useStringFilterParams } from "../../dashboard/utils";
import LoaderTable from "../../../../../../../common/components/LoaderTable/LoaderTable";
import SearchInput from "../../../../../../../common/components/SearchInput/SearchInput";
import css from "./crosslist.module.scss";

interface ICurriculumProps {
  project: any;
  isLoading: boolean;
  searchTerm: string;
  forecastContext: IForecastContextInterface;
  setDemandFilter: (page: number, searchTerm: string) => void;
}

interface IPageInfo {
  total: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  page: number;
  size: number;
}

const CrossList: React.FC<ICurriculumProps> = (props: ICurriculumProps) => {
  const { project, setDemandFilter, isLoading, searchTerm } = props;
  const context: IForecastContextInterface = props.forecastContext;
  const [data, setData] = useState(project.unit.demandsPage.items);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingPaginator, setIsLoadingPaginator] = useState(false);
  const [confirmCrosslist, setConfirmCrosslist] = useState<boolean>(
    project.unit.crosslistConfirmation,
  );
  const demandDistribution = project.unit.demandDistribution;
  const [disabledCheckConfirmCrosslist, setDisabledCheckConfirmCrosslist] = useState(true);
  const pageInfo: IPageInfo = project.unit.demandsPage.pageInfo;
  const editDemand = useEditDemand();
  const [confirmUnit] = useMutation(EDIT_CONFIRM_CROSSLIST);
  const routeBack = context.routes.dashboard();
  const routes = context.routes;
  const stringFilter = useStringFilterParams();
  const [loading, setLoading] = useState(false);
  const [totalAdjusted, setTotalAdjusted] = useState(project.unit.totalAdjusted);
  const [lastRowUpdatedId, setLastRowUpdatedId] = useState<number>(null);
  let countDemandOwn = 0;
  const selectedRow = data.map((value: any) => {
    if (value.own) countDemandOwn++;
    return value.checked;
  });
  const userHasEditPermission =
    fakeAuth.getUserData().role.permissions?.crosslist?.some((item: any) => item === "update") ||
    false;

  const totalProjected = project.unit.totalProjected;

  useEffect(() => {
    setData(project.unit.demandsPage.items);
  }, [project]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingPaginator(false);
      setIsLoadingFilter(false);
    }
  }, [isLoading]);

  const handleSaveDemand = async () => {
    try {
      setLoading(true);
      const unitId = project.unit.id;
      const demandAdjustments: DemandAdjustment[] = data.map((demand: any) => {
        return {
          demandId: demand.id,
          adjusted: demand.adjusted,
          confirmed: demand.checked,
        };
      });
      const variables = {
        unitId,
        demandAdjustments,
      };
      if (demandAdjustments.length > 0) {
        const response = await editDemand.editDemand(variables, context);
        setTotalAdjusted(response.data.data.editDemand.totalAdjusted);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    handleSaveDemand();
  }, [data]);

  const handleSaveConfirmCrosslist = async newCheckValue => {
    try {
      setLoading(true);
      const variables = {
        unitId: project.unit.id,
        confirmed: newCheckValue,
      };
      const response = await confirmUnit({ variables });
      if (response.data.confirmUnit.status.code === 200) {
        setConfirmCrosslist(newCheckValue);
        setLoading(false);
        window.location.reload();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const curriculumCell = (data: any) => {
    const demand: any = data.row.original;
    const levelId = demand?.relatedUnits?.level?.id;
    const levelRoute = levelId ?? context?.routes.unit(levelId);
    return levelId ? (
      <a href={`${levelRoute}${stringFilter}`}>{data.value}</a>
    ) : (
      `${data.value || ""}`
    );
  };

  const courseCell = (data: any) => {
    const demand: any = data.row.original;
    const courseId = demand?.relatedUnits?.subject?.id;
    const courseRoute = courseId ?? context?.routes.unit(courseId);
    return courseId ? (
      <a href={`${courseRoute}${stringFilter}`}>{data.value}</a>
    ) : (
      `${data.value || ""}`
    );
  };

  const adjustedCell = (data: any) => {
    const demand: any = data.row.original;
    if (!demand?.own) return `${data.value}`;
    return (
      <InputCell
        disabled={data.state.selectedRowIds[data.row.index] || confirmCrosslist || loading}
        value={data.value}
        onChange={(value: string) => {
          const inputValue = value ? parseInt(value) : 0;
          data.updateImplementedData(setData, data.row.index, "adjusted", inputValue);
          setLastRowUpdatedId(data.row.id);
        }}
      />
    );
  };

  const headerCheckedCell = (data: any) => {
    const handleChange = () => {
      const { toggleAllRowsSelected, updateImplementAllData, column, isAllRowsSelected } = data;
      const newChecked = !isAllRowsSelected;
      toggleAllRowsSelected(true);
      updateImplementAllData(setData, column.id, newChecked);
      setLastRowUpdatedId(null);
    };
    return (
      <div className={cx("container-row", "row_align--center")}>
        <CheckboxCell
          {...data.getToggleAllRowsSelectedProps({ onChange: handleChange })}
          disabled={countDemandOwn === 0 || confirmCrosslist || loading}
        />
        <label className={css.labelCheckbox}>Confirmada</label>
      </div>
    );
  };

  const checkedCell = (data: any) => {
    const demand: any = data.row.original;
    const disabled = !demand?.own;
    const handleChange = () => {
      const value = !data.row.isSelected;
      data.toggleRowSelected(data.row.id, value);
      data.updateImplementedData(setData, data.row.index, data.column.id, value);
      setLastRowUpdatedId(data.row.id);
    };
    return (
      <div className={cx("container-row", "row_align--center")}>
        <CheckboxCell
          {...data.row.getToggleRowSelectedProps({ onChange: handleChange })}
          disabled={disabled || loading || confirmCrosslist}
        />
        {loading && data.row.id === lastRowUpdatedId && (
          <label className={css.labelCheckbox}>Cargando ...</label>
        )}
        {!loading && data.row.id === lastRowUpdatedId && (
          <label className={cx(css.labelCheckbox, css.labelCheckbox__confirm)}>
            <Icon icon="check" className={cx(css.icon)} />
            Guardado
          </label>
        )}
      </div>
    );
  };

  const ruleCodeCell = (data: any) => {
    const demand = data.row.original;
    const { course } = demand;
    const { ruleCode } = course;

    // when isRule has only one rule code

    const { relatedUnits } = demand;
    if (relatedUnits?.ruleCode?.id) {
      const ruleCoderoute = context.routes.unit(relatedUnits.ruleCode.id);
      return (
        <a key={relatedUnits.ruleCode.code} href={`${ruleCoderoute}${stringFilter}`}>
          {relatedUnits.ruleCode.code}
        </a>
      );
    } else {
      return "";
    }
  };

  const columns = [
    {
      Header: "Carrera",
      accessor: "course.curriculum.program.name",
      minWidth: 200,
    },
    {
      Header: "Currículo",
      accessor: "course.curriculum.name",
      maxWidth: 120,
      Cell: (data: any) => curriculumCell(data),
    },
    {
      Header: "Asignatura",
      accessor: "course.name",
      minWidth: 300,
      Cell: (data: any) => courseCell(data),
    },
    {
      Header: "Código Regla",
      Cell: (data: any) => ruleCodeCell(data),
      maxWidth: 120,
    },
    {
      Header: "Proyectada",
      accessor: "value",
    },
    {
      Header: "Ajustada",
      accessor: "adjusted",
      Cell: (data: any) => adjustedCell(data),
    },
    {
      Header: (data: any) => headerCheckedCell(data),
      accessor: "checked",
      minWidth: 130,
      Cell: (data: any) => checkedCell(data),
    },
  ];

  return (
    <section className={cx(css.cntCrossList, "container-row")}>
      <Link
        className={cx(css.back, "container-row", "row_align--center")}
        to={`${routeBack}${stringFilter}`}
      >
        <Icon icon="arrow-left" className={cx(css.back_icon)} />
        volver al inicio
      </Link>
      <div className={cx(css.cntCrossList_header, "container-row", "col_12")}>
        <span className={cx(css.cntCrossList_title)}>
          Lista cruzada {` | ${project.unit.label}`}
        </span>
        <Link
          className={cx(css.edit, "col_3", "row_align--center")}
          to={`${routes.crosslistEdit(project.unit.id)}`}
        >
          <Icon icon="editnew" className={cx(css.edit_icon)} />
          Editar
        </Link>
        {userHasEditPermission && demandDistribution.length > 0 && (
          <div className={cx(css.crosslistConfirmation, "container-row")}>
            <Checkbox
              label={"Aprobada"}
              check={confirmCrosslist}
              disabled={disabledCheckConfirmCrosslist}
              onChange={handleSaveConfirmCrosslist}
            />
          </div>
        )}
        {!userHasEditPermission && demandDistribution.length > 0 && (
          <div className={cx(css.crosslistConfirmation, "container-row")}>
            <p className={css.crosslistConfirmation}>
              <Icon icon="check" className={cx(css.check_icon)} />
              Aprobada
            </p>
          </div>
        )}
      </div>

      {
        <div className={cx(css.headerDemand, "col_12")}>
          <HeaderDemand
            onChangeDemands={apertureDifference =>
              setDisabledCheckConfirmCrosslist(apertureDifference !== 0)
            }
            disabled={confirmCrosslist}
            projectId={project.unit.id}
            totalAdjusted={totalAdjusted}
            totalProjected={totalProjected}
            demandDistribution={demandDistribution}
          />
        </div>
      }

      <div className="container-row row_aling--center">
        <div className={cx("col_2", css.cntSearchInput)}>
          <SearchInput
            placeholder="Filtrar"
            value={searchTerm}
            onClick={inputValue => {
              setIsLoadingFilter(true);
              setDemandFilter(1, inputValue);
            }}
          />
        </div>
        {isLoadingFilter && (
          <div className={cx("col_1", css.cntSearchInputLoader)}>
            <LoaderTable />
          </div>
        )}
      </div>

      <Table columns={columns} data={data} initSelectedRowIds={selectedRow} />

      <div className={cx(css.cntCrossList_save, "container-row", "row--center")}>
        <Paginator
          total={pageInfo.total}
          pageSize={pageInfo.size}
          current={pageInfo.page}
          onChange={currentPage => {
            setIsLoadingPaginator(true);
            setDemandFilter(currentPage, "");
          }}
        />
        {isLoadingPaginator && (
          <div className={cx(css.cntPaginatorLoader)}>
            <LoaderTable />
          </div>
        )}
      </div>
    </section>
  );
};

export default CrossList;
