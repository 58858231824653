import gql from "graphql-tag";

const EDIT_UNIT_DEMAND_DISTRIBUTION = gql`
  mutation editUnitDemandDistribution($unitId: ID!, $demandDistribution: [TagInput]!) {
    editUnitDemandDistribution(unitId: $unitId, demandDistribution: $demandDistribution) {
      status {
        code
        msg
      }
      unit {
        demandDistribution {
          tagId
          name
          value
        }
      }
    }
  }
`;

export default EDIT_UNIT_DEMAND_DISTRIBUTION;
