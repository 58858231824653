import React from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import "./paginator.scss";

interface IPaginatorProps {
  total: number;
  pageSize: number;
  current: number;
  onChange: (current: number, pageSize: number) => void;
}

const Paginator: React.FC<IPaginatorProps> = (props: IPaginatorProps) => {
  const { total, pageSize, onChange, current } = props;
  return (
    <React.Fragment>
      <Pagination
        className="container-row row--center"
        showQuickJumper
        showSizeChanger
        defaultPageSize={pageSize}
        current={current}
        defaultCurrent={1}
        total={total}
        onChange={onChange}
      />
    </React.Fragment>
  );
};

export default Paginator;
