import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import Login from "./modules/session/Login";
import Logout from "./modules/session/Logout";
import Shell from "./common/components/shell";
import { AuthProvider } from "./common/contexts/AuthContext";
import "./assets/scss/app.scss";

import fakeAuth from "./authClient";

interface IAppProps extends RouteComponentProps<{}> {
  history: any;
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }: any) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const Protected = () => (
  <AuthProvider>
    <Shell />
  </AuthProvider>
);

class App extends Component<IAppProps> {
  render() {
    const isAuthenticated = fakeAuth.isAuthenticated();
    return (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <PrivateRoute path="/" component={Protected} isAuthenticated={isAuthenticated} />
      </Switch>
    );
  }
}

export default withRouter(App);
