import React, { useEffect, forwardRef, MutableRefObject, useRef } from "react";
import css from "./table.module.scss";

interface IProps {
  indeterminate?: boolean;
  name: string;
  checked?: boolean;
  disabled?: boolean;
}

interface IIndeterminate {
  indeterminate?: boolean;
}

const useCombinedRefs = (...refs: any): MutableRefObject<any> => {
  const targetRef = useRef();
  useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IProps>(
  ({ indeterminate, ...rest }: IIndeterminate, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return <input type="checkbox" {...rest} ref={combinedRef} className={css.checkboxTable} />;
  },
);

IndeterminateCheckbox.displayName = "Check";
export default IndeterminateCheckbox;
