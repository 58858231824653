import React, { useState } from "react";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import cx from "classnames";
import Icon from "../Icon/Icon";
import css from "./accordion.module.scss";

/**
 * Para usar este componente hay que contenerlo dentro de <Accordion/> importando:
 * import { Accordion } from "react-accessible-accordion";
 * <Accordion/> puede tener como atributo cn el fin de definir comportamiento de <AccordionItem/> :
 * - allowMultipleExpanded
 * - allowZeroExpanded
 * - preExpanded
 * - onChange
 * - dangerouslySetExpanded
 * Documentación: https://react-accessible-accordion.springload.co.nz/
 */

interface IAccordionProps {
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  id: number;
}
const AccordionComponent: React.FC<IAccordionProps> = (props: IAccordionProps) => {
  const { header, children, className, id } = props;
  const [rotateArrow, setRotateArrow] = useState(false);

  return (
    <AccordionItem className={cx(css.accordion, className)} key={id}>
      <AccordionItemHeading
        className={cx(css.header, "container-row")}
        onClick={() => setRotateArrow(!rotateArrow)}
      >
        <AccordionItemButton className={cx(css.header_item, "container-row", "row--between")}>
          <>
            <div className={cx("col_10")}> {header}</div>
            <Icon icon="arrow-down" className={cx(css.icon, rotateArrow && css.icon__active)} />
          </>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className={cx(css.content)}>{children}</AccordionItemPanel>
    </AccordionItem>
  );
};

export default AccordionComponent;
