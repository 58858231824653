import { GRAPHQL_URI } from "./common/config";

const urlParams = new URLSearchParams(window.location.search);

const user = {
  setData: (userData: object) =>
    localStorage.setItem("__darwined_user_data", JSON.stringify(userData)),
  getData: () => JSON.parse(localStorage.getItem("__darwined_user_data") || "{}"),
  removeData: () => {
    localStorage.removeItem("__darwined_user_data");
    return true;
  },
  isAuthenticated: () => user.getData().isAuthenticated === true || false,
};

const fetchData = async (token: string | null, callback: Function) => {
  if (!token) return;
  const response = await fetch(`${GRAPHQL_URI}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `query { auth { status { code msg } user { username email institutionId logoutUrl role { role permissions {crosslist} } } project { id institutionId active logoutUrl state access { workspaceName workspaceId scenarioName scenarioId originName originId } } } }`,
    }),
  });
  callback(await response.json());
};

const isValidUser = async () => {
  let isValid = false;
  if (urlParams.has("token")) {
    const token = urlParams.get("token");
    await fetchData(token, (response: any) => {
      if (response.data && response.data.auth && response.data.auth.status.code === 200) {
        user.setData({
          ...response.data.auth.user,
          isAuthenticated: true,
          token: token,
          project: response.data.auth.project,
        });
        isValid = true;
      } else {
        isValid = false;
      }
    });
  }
  return isValid;
};

const fakeAuth = {
  async signIn(callback: Function) {
    const isValid = await isValidUser();
    callback(isValid);
  },
  signOut(callback: Function) {
    if (user.removeData()) {
      callback(true);
    } else {
      callback(false);
    }
  },
  getUserData: () => user.getData(),
  isAuthenticated: () => user.isAuthenticated(),
};

export default fakeAuth;
