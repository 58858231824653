import React from "react";

import "./search-input.scss";

interface IPropsSearchInput {
  className?: string;
  placeholder?: string;
  value?: string;
  onClick?: (inputValue: string) => void;
}

const SearchInput = (props: IPropsSearchInput) => {
  const { className, placeholder, onClick } = props;
  return (
    <section className={`cnt_search  ${className}`}>
      <div className="cnt_search-input">
        <input
          type="text"
          className="input_search"
          placeholder={placeholder}
          onChange={e => onClick(e.target.value)}
        />
      </div>
    </section>
  );
};

export default SearchInput;
