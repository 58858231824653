import React, { useState } from "react";
import { Query, Mutation } from "react-apollo";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadCrumb } from "../../../../../../common";
import { Form, Input, TextArea, Button, Select, Divider } from "@foris/foris-ui";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import {
  GET_WORKSPACES_QUERY,
  GET_SCENARIOS_QUERY,
  GET_ORIGINS_QUERY,
  CREATE_NEW_PROJECT_MUTATION,
} from "./queries";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";

const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
};

const AddForm: React.FC<RouteComponentProps<{}> & any> = (props: any) => {
  const [isLoading, setIsloading] = useState(false);
  const [formValues, setFormValues] = useState({
    isSubmitted: false,
    values: {
      title: { required: true, value: "", isValid: false },
      startDate: { required: true, value: "", isValid: false },
      endDate: { required: true, value: "", isValid: false },
      description: { required: false, value: "", isValid: false },
      workspace: { required: true, value: { value: "", text: "" }, isValid: false },
      scenario: { required: true, value: { value: "", text: "" }, isValid: false },
      origin: { required: true, value: { value: "", text: "" }, isValid: false },
    },
  });
  const routes = props.context.routes;
  const breadCrumbItems = [
    { content: "Proyectos", link: true, to: routes.projectList() },
    { content: "Crear proyecto", link: false },
  ];

  const handleChange = (
    e: React.SyntheticEvent,
    { name, value, options }: { name: string; value: string; options: any },
  ) => {
    const currentFormValue: any = Object.assign({}, formValues);
    const isSelectField = name === "workspace" || name === "scenario" || name === "origin";

    if (isSelectField && options) {
      let selectedField = [];
      selectedField = options.filter((item: any) => item.value === value);
      currentFormValue.values[name].value = selectedField[0];
      currentFormValue.values[name]["isValid"] = !!selectedField.length;
      setFormValues(currentFormValue);
    } else {
      currentFormValue.values[name].value = value;
      currentFormValue.values[name]["isValid"] = !!value.length;
      setFormValues(currentFormValue);
    }
  };

  const formWithErrors = () => {
    setFormValues({ ...formValues, isSubmitted: true });
    const obj: any = formValues.values;
    const hasError = Object.keys(obj).filter(
      (key: string) =>
        (obj[key].required && obj[key].value === "") ||
        (obj[key].required &&
          obj[key].value.hasOwnProperty("value") &&
          obj[key].value.value === ""),
    );

    return !!hasError.length;
  };

  return (
    <Mutation
      mutation={CREATE_NEW_PROJECT_MUTATION}
      onCompleted={(data: any) => {
        if (
          data &&
          data.addProject &&
          data.addProject.status &&
          data.addProject.status.code !== 200
        ) {
          switch (data.addProject.status.msg) {
            case "PROJECT_MIGRATING":
              toast.warn("Ya existe un proyecto en migración actualmente.", TOAST_OPTIONS);
              setIsloading(false);
              break;
            default:
              break;
          }

          return false;
        }

        if (data && data.addProject && data.addProject.project) {
          const currentStorage = JSON.parse(localStorage.__darwined_user_data);
          const project = {
            id: data.addProject.project.id,
            active: data.addProject.project.active,
            state: data.addProject.project.state,
            description: data.addProject.project.description,
          };
          currentStorage["project"] = project;
          localStorage.setItem("__darwined_user_data", JSON.stringify(currentStorage));
          props.context.updateCurrentProject(project);
          setIsloading(false);
          console.log("on Complete data after MUTATION --> ", data);
        }
      }}
    >
      {(createProject: any) => (
        <>
          <BreadCrumb items={breadCrumbItems} />
          {/* <h3 style={{ margin: "0px 0px 1.5em 0px", padding: "0px" }}>
            Test-41 <span style={{ fontWeight: 400 }}>(BASE / UPCH / Origen: 525)</span>
          </h3> */}
          <div className="row">
            <div className="col">
              <div style={{ backgroundColor: "white", padding: "2em 3em", marginBottom: "4em" }}>
                <h3>Crear proyecto</h3> <br />
                <div className="col-md-6" style={{ padding: "0px" }}>
                  <Form>
                    <Form.Group className="row">
                      <Query query={GET_WORKSPACES_QUERY}>
                        {({ loading, error, data }: any): any => {
                          if (loading)
                            return (
                              <Form.Field
                                loading
                                className="col-md-4 ellipsisText"
                                control={Select}
                                options={[]}
                                label={{ children: "Ambiente" }}
                                placeholder="Ambiente"
                                disabled={true}
                              />
                            );
                          if (error) return `Error! ${error.message}`;

                          const options: any = data.workspaces
                            ? data.workspaces.map((item: any) => ({
                                key: item.code,
                                text: item.name,
                                value: item.id,
                              }))
                            : [];

                          return (
                            <Form.Field
                              className="col-md-4 ellipsisText"
                              control={Select}
                              options={options}
                              label={{ children: "Ambiente" }}
                              placeholder="Ambiente"
                              search
                              error={
                                formValues.isSubmitted &&
                                formValues.values.workspace.required &&
                                !formValues.values.workspace.isValid
                              }
                              name="workspace"
                              onChange={handleChange}
                            />
                          );
                        }}
                      </Query>

                      {formValues.values.workspace.value.value ? (
                        <Query
                          query={GET_SCENARIOS_QUERY}
                          variables={{ workspaceId: formValues.values.workspace.value.value }}
                        >
                          {({ loading, error, data }: any): any => {
                            if (loading)
                              return (
                                <Form.Field
                                  loading
                                  className="col-md-4 ellipsisText"
                                  control={Select}
                                  options={[]}
                                  label={{ children: "Escenario" }}
                                  placeholder="Escenario"
                                  disabled={formValues.values.workspace.value.value === ""}
                                  error={
                                    formValues.isSubmitted &&
                                    formValues.values.scenario.required &&
                                    !formValues.values.scenario.isValid
                                  }
                                />
                              );
                            if (error) return `Error! ${error.message}`;

                            const options: any = data.scenarios
                              ? data.scenarios.map((item: any) => ({
                                  key: item.id,
                                  text: item.name,
                                  value: item.id,
                                }))
                              : [];

                            return (
                              <Form.Field
                                className="col-md-4 ellipsisText"
                                control={Select}
                                options={options}
                                label={{ children: "Escenario" }}
                                placeholder="Escenario"
                                search
                                disabled={formValues.values.workspace.value.value === ""}
                                error={
                                  formValues.isSubmitted &&
                                  formValues.values.scenario.required &&
                                  !formValues.values.scenario.isValid
                                }
                                name="scenario"
                                onChange={handleChange}
                              />
                            );
                          }}
                        </Query>
                      ) : (
                        <Form.Field
                          className="col-md-4 ellipsisText"
                          control={Select}
                          options={[]}
                          label={{ children: "Escenario" }}
                          placeholder="Escenario"
                          disabled={formValues.values.workspace.value.value === ""}
                          error={
                            formValues.isSubmitted &&
                            formValues.values.scenario.required &&
                            !formValues.values.scenario.isValid
                          }
                        />
                      )}

                      {formValues.values.scenario.value.value ? (
                        <Query
                          query={GET_ORIGINS_QUERY}
                          variables={{ scenarioId: formValues.values.scenario.value.value }}
                        >
                          {({ loading, error, data }: any): any => {
                            if (loading)
                              return (
                                <Form.Field
                                  loading
                                  className="col-md-4 ellipsisText"
                                  control={Select}
                                  options={[]}
                                  label={{ children: "Origen" }}
                                  placeholder="Origen"
                                  disabled={formValues.values.scenario.value.value === ""}
                                  error={
                                    formValues.isSubmitted &&
                                    formValues.values.origin.required &&
                                    !formValues.values.origin.isValid
                                  }
                                />
                              );
                            if (error) return `Error! ${error.message}`;

                            const options: any = data.origins
                              ? data.origins.map((item: any) => ({
                                  key: item.id,
                                  text: `${item.label} (total: ${item.demandCount})`,
                                  value: item.id,
                                  disabled: item.demandCount === 0,
                                }))
                              : [];

                            return (
                              <Form.Field
                                className="col-md-4 ellipsisText"
                                control={Select}
                                name="origin"
                                options={options}
                                label={{ children: "Origen" }}
                                placeholder="Origen"
                                search
                                disabled={formValues.values.scenario.value.value === ""}
                                error={
                                  formValues.isSubmitted &&
                                  formValues.values.origin.required &&
                                  !formValues.values.origin.isValid
                                }
                                onChange={handleChange}
                              />
                            );
                          }}
                        </Query>
                      ) : (
                        <Form.Field
                          className="col-md-4 ellipsisText"
                          control={Select}
                          options={[]}
                          label={{ children: "Origen" }}
                          placeholder="Origen"
                          disabled={formValues.values.scenario.value.value === ""}
                          error={
                            formValues.isSubmitted &&
                            formValues.values.origin.required &&
                            !formValues.values.origin.isValid
                          }
                        />
                      )}
                    </Form.Group>

                    <Divider section />
                    <Form.Field
                      control={Input}
                      label="Título"
                      width={8}
                      placeholder="Ingresa un título"
                      error={
                        formValues.isSubmitted &&
                        formValues.values.title.required &&
                        !formValues.values.title.isValid
                      }
                      name="title"
                      onChange={handleChange}
                    />
                    {/* <Form.Group>
                      <Form.Field
                        width={6}
                        control={Input}
                        label="Apertura"
                        type="date"
                        placeholder=""
                        error={
                          formValues.isSubmitted &&
                          (formValues.values.startDate.required &&
                            !formValues.values.startDate.isValid)
                        }
                        name="startDate"
                        onChange={handleChange}
                      />
                      <Form.Field
                        width={6}
                        control={Input}
                        label="Cierre"
                        type="date"
                        placeholder=""
                        error={
                          formValues.isSubmitted &&
                          (formValues.values.endDate.required && !formValues.values.endDate.isValid)
                        }
                        name="endDate"
                        onChange={handleChange}
                      />
                    </Form.Group> */}

                    <Form.Group>
                      <Form.Field
                        width={4}
                        control={DatePicker}
                        locale={es}
                        label="Apertura"
                        placeholderText="Apertura"
                        selected={
                          formValues.values.startDate.value
                            ? new Date(`${formValues.values.startDate.value}T00:00:00`)
                            : null
                        }
                        dateFormat="dd.MM.yyyy"
                        error={
                          formValues.isSubmitted &&
                          formValues.values.startDate.required &&
                          !formValues.values.startDate.isValid
                        }
                        onChange={(date: Date, e: React.SyntheticEvent) => {
                          if (date) {
                            handleChange(e, {
                              name: "startDate",
                              value: date.toISOString().split("T")[0],
                              options: null,
                            });
                          }
                        }}
                      />

                      <Form.Field
                        width={4}
                        control={DatePicker}
                        locale={es}
                        placeholderText="Cierre"
                        label="Cierre"
                        selected={
                          formValues.values.endDate.value
                            ? new Date(`${formValues.values.endDate.value}T00:00:00`)
                            : null
                        }
                        dateFormat="dd.MM.yyyy"
                        error={
                          formValues.isSubmitted &&
                          formValues.values.endDate.required &&
                          !formValues.values.endDate.isValid
                        }
                        onChange={(date: Date, e: React.SyntheticEvent) => {
                          if (date) {
                            handleChange(e, {
                              name: "endDate",
                              value: date.toISOString().split("T")[0],
                              options: null,
                            });
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Field
                      control={TextArea}
                      label="Descripción"
                      placeholder="Ingresa una descripción"
                      error={
                        formValues.isSubmitted &&
                        formValues.values.description.required &&
                        !formValues.values.description.isValid
                      }
                      name="description"
                      onChange={handleChange}
                    />

                    <br />
                    <div className="d-flex align-items-end" style={{ textAlign: "right" }}>
                      <Button
                        type="reset"
                        style={{ marginRight: "1em", display: "inline-block" }}
                        onClick={() => {
                          props.history.push(routes.projectList());
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        style={{ display: "inline-block" }}
                        loading={isLoading}
                        type="submit"
                        primary
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault();

                          if (formWithErrors()) {
                            return false;
                          } else {
                            setIsloading(true);

                            createProject({
                              variables: {
                                projectInput: {
                                  title: formValues.values.title.value,
                                  institutionId: props.context.auth.institutionId,
                                  startDate: formValues.values.startDate.value,
                                  endDate: formValues.values.endDate.value,
                                  description: formValues.values.description.value,
                                  access: {
                                    workspaceId: formValues.values.workspace.value.value,
                                    workspaceName: formValues.values.workspace.value.text,
                                    scenarioId: formValues.values.scenario.value.value,
                                    scenarioName: formValues.values.scenario.value.text,
                                    originId: formValues.values.origin.value.value,
                                    originName: formValues.values.origin.value.text,
                                  },
                                },
                              },
                            });
                          }
                        }}
                      >
                        Crear
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Mutation>
  );
};

const AppFormWithRouter = withRouter(AddForm);

const AddProjectPageContainer = ({
  forecastContext,
}: {
  forecastContext?: IForecastContextInterface;
}) => forecastContext && <AppFormWithRouter context={forecastContext} />;

export default withForecastContext(AddProjectPageContainer);
