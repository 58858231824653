import React from "react";
import { useQuery } from "react-apollo";
import Select from "react-select";
import { GET_PROJECTS } from "./context.queries";
import Skeleton from "react-loading-skeleton";
import { Project } from "../../../../../models/ISchema";
interface ISelectProps {
  scenarioValue: any;
  workspaceValue: any;
  projectValue: any;
  onChange: Function;
  institutionId: string;
}

interface ISelectValue {
  value: string;
  label: string;
}

const customStyles = {
  control: (values: any) => ({
    ...values,
    fontSize: "0.875rem",
    border: "1px solid #e5e8ec",
  }),
  menu: (provided: any) => ({
    ...provided,
    color: "#575d62",
    fontSize: "0.875rem",
  }),
};

const ProjectSelect: React.FC<ISelectProps> = (props: ISelectProps) => {
  const { onChange, scenarioValue, workspaceValue, projectValue, institutionId } = props;

  const { loading, error, data } = useQuery<any, any>(GET_PROJECTS, {
    variables: {
      institutionId,
      filter: {
        active: true,
        access: {
          scenarioId: scenarioValue ? scenarioValue.value : "" || "",
          workspaceId: workspaceValue ? workspaceValue.value : "" || "",
        },
      },
    },
    fetchPolicy: "no-cache",
  });

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <Skeleton height={"100%"} />
      </div>
    );
  if (error)
    return (
      <div style={{ padding: "10px 0" }}>
        <Select
          options={[]}
          styles={customStyles}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#e5e8ec",
              primary: "#6993c9",
              neutral80: "#575d62",
              neutral90: "#575d62",
            },
          })}
          placeholder="No hay datos..."
        />
      </div>
    );

  const projects: Array<Project> = data.projects;
  const options = projects.map((project: Project) => {
    return { label: project.title, value: project.id };
  });

  return (
    <div style={{ padding: "10px 0" }}>
      <Select
        styles={customStyles}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#e5e8ec",
            primary: "#6993c9",
            neutral80: "#575d62",
            neutral90: "#575d62",
          },
        })}
        maxMenuHeight={100}
        options={options}
        placeholder="Seleccionar"
        value={projectValue}
        onChange={(value: any): void => onChange(value)}
      />
    </div>
  );
};
export default ProjectSelect;
