import React from "react";
import { Button } from "@foris/foris-ui";
import Modal from "../../modal/Modal";
import css from "./download-report-modal.module.scss";

interface IDwonloadReportModalProps {
  open: boolean;
  title?: string;
  reportResponseError: boolean;
  reportLink?: string;
  linkReadyToDownload: boolean;
  setModalFileConfirmation: Function;
}

const DownloadReportModal: React.FC<IDwonloadReportModalProps> = (
  props: IDwonloadReportModalProps,
) => {
  const {
    open,
    title,
    reportResponseError,
    reportLink,
    linkReadyToDownload,
    setModalFileConfirmation,
  } = props;
  return (
    <Modal
      title={title}
      show={open}
      onClose={() => setModalFileConfirmation(false)}
      headerColor="#294870"
    >
      <div className={css.contextContent}>
        {!linkReadyToDownload && <p className={css.contextContent_text}>Generando el reporte...</p>}

        {!reportResponseError && linkReadyToDownload && reportLink && (
          <>
            <p className={css.contextContent_text}>El reporte se ha generado exitosamente.</p>
            {/* TODO: Reemplazar <Button/> de forisUI por componente propio */}
            <Button primary className={css.contextContent_btn}>
              <a
                href={reportLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white" }}
              >
                Descargar reporte
              </a>
            </Button>
          </>
        )}

        {!reportResponseError && linkReadyToDownload && !reportLink && (
          <>
            <p>No tienes reportes disponible para descargar.</p>
          </>
        )}

        {reportResponseError && <p>Ha ocurrido un error, inténtelo nuevamente.</p>}
      </div>
    </Modal>
  );
};

export default DownloadReportModal;
