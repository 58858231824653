import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps, useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import ContextModal from "./ContextModal";
import { withForecastContext } from "../../../../modules/forecast/context";
import Forecast from "../../../../routes/Forecast";
import { GET_SCENARIOS, GET_WORKSPACES, GET_PROJECTS } from "./contextSelect/context.queries";
import { Project, Scenario, Workspace } from "../../../../models/ISchema";
import css from "./context-modal.module.scss";

interface IContextScenarioProps extends RouteComponentProps<{}> {
  history: any;
  onSetCalendarRanges?: any;
  forecastContext: any;
}

const ContextScenario: React.FC<IContextScenarioProps> = (props: IContextScenarioProps) => {
  const client = useApolloClient();
  const params: any = useParams();
  const [changeCx, setChangeCx] = useState(false);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const routes: Forecast = props.forecastContext ? props.forecastContext.routes : null;

  const getQueryContext = async (workspace: string, scenario: string, project: string) => {
    try {
      if (workspace && scenario && project) {
        const workspaceData = await client.query({
          query: GET_WORKSPACES,
          variables: {},
        });
        const workspaceList: Array<Workspace> = workspaceData?.data?.workspaces;
        const selectedWorkspace: Workspace = workspaceList?.find(value => value.id === workspace);
        const { data } = await client.query({
          query: GET_SCENARIOS,
          variables: { workspaceId: workspace },
        });
        const scenarioList: Array<Scenario> = data?.scenarios;
        const selectedScenario: Scenario = scenarioList.find(value => value.id === scenario);
        const projectData = await client.query({
          query: GET_PROJECTS,
          variables: {
            institutionId: props.forecastContext?.auth?.institutionId,
            filter: {
              active: true,
              access: {
                scenarioId: scenario,
                workspaceId: workspace,
              },
            },
          },
        });
        const projectList: Array<Project> = projectData?.data?.projects;
        const selectedProject: Project = projectList?.find(value => value.id === project);

        if (selectedWorkspace && selectedScenario && selectedProject) {
          setCurrentWorkspace({ label: selectedWorkspace.name, value: selectedWorkspace.id });
          setCurrentScenario({ label: selectedScenario.name, value: selectedScenario.id });
          setCurrentProject({ label: selectedProject.title, value: selectedProject.id });
        } else {
          setChangeCx(true);
        }
      } else {
        setChangeCx(true);
      }
    } catch (error) {
      console.log(error);
      setChangeCx(true);
    }
  };

  /**
   * Change states
   * @param moduleConfig ''
   */
  const handleSaveContext = async (newContext: any) => {
    // TODO: save new context in params
    try {
      const { history } = props;
      const newValue = newContext.context;

      // Set current context
      setCurrentWorkspace(newValue.workspace);
      setCurrentScenario(newValue.scenario);
      setCurrentProject(newValue.project);

      if (routes) {
        routes.setContext({
          workspace: newValue.workspace.value,
          scenario: newValue.scenario.value,
          project: newValue.project.value,
        });
        props.forecastContext.updateRoutesForecast(routes);
        history.push(routes.dashboard());
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!routes) window.location.reload();
    // if local states are null
    if (!currentWorkspace && !currentScenario && !currentProject) {
      const { workspace, scenario, project } = routes.context;
      const paramWorkspace: string = params.workspace;
      const paramScenario: string = params.scenario;
      const paramProject: string = params.project;
      // if exist context params
      if (paramWorkspace && paramScenario && paramProject) {
        if (
          workspace !== paramWorkspace ||
          scenario !== paramScenario ||
          project !== paramProject
        ) {
          routes.setContext({
            workspace: paramWorkspace,
            scenario: paramScenario,
            project: paramProject,
          });
          props.forecastContext.updateRoutesForecast(routes);
          // set with params context
          getQueryContext(paramWorkspace, paramScenario, paramProject);
        }
      } else {
        // set with init context in props
        getQueryContext(workspace, scenario, project);
      }
    }
  }, [currentWorkspace, currentScenario, currentProject]);

  return (
    <>
      <div
        className={cx(css.context, "container-row", "row--between", "row_align--center")}
        onClick={(): void => setChangeCx(true)}
      >
        <section className={css.title}>
          <p className={css.title_label}>AMB</p>
          <p className={css.title_info}>
            {currentWorkspace
              ? currentWorkspace?.label.length > 10
                ? `${currentWorkspace?.label.substr(0, 10)}...`
                : currentWorkspace?.label
              : `-`}
          </p>
        </section>
        <section className={css.title}>
          <p className={css.title_label}>ESC</p>
          <p className={css.title_info}>
            {currentScenario
              ? currentScenario?.label.length > 10
                ? `${currentScenario?.label.substr(0, 10)}...`
                : currentScenario?.label
              : `-`}
          </p>
        </section>
        <section className={css.title}>
          <p className={css.title_label}>PRO</p>
          <p className={css.title_info}>
            {currentProject
              ? currentProject?.label.length > 10
                ? `${currentProject?.label.substr(0, 10)}...`
                : currentProject?.label
              : `-`}
          </p>
        </section>
      </div>
      <ContextModal
        institutionId={props.forecastContext?.auth?.institutionId}
        open={changeCx}
        workspace={currentWorkspace}
        scenario={currentScenario}
        project={currentProject}
        onClose={(): void => {
          setChangeCx(false);
        }}
        onSave={(newContext: any) => handleSaveContext(newContext)}
      />
    </>
  );
};

export default withRouter(withForecastContext(ContextScenario));
