import React, { Component } from "react";
import { Link } from "react-router-dom";

class InstructorList extends Component {
  state = {
    instructors: [
      { id: 1, code: "188484", name: "Germán Casas" },
      { id: 2, code: "142342", name: "Ramón Ortega" },
      { id: 3, code: "134534", name: "Camila Gómez" },
      { id: 4, code: "175645", name: "Eliana Narvaez" },
      { id: 5, code: "143253", name: "David Camejo" },
    ],
  };
  render() {
    return (
      <section className="main-section hero is-fullheight-with-navbar">
        <div className="hero-body" style={{ paddingTop: "2rem" }}>
          <div className="container is-fluid">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/wizard">Wizard</Link>
                </li>
                <li className="is-active">
                  <Link to="#" aria-current="page">
                    Docentes
                  </Link>
                </li>
              </ul>
            </nav>

            <div className="columns">
              <div className="column is-content" style={{ padding: 0, marginTop: "40px" }}>
                <div className="instructor-list">
                  {this.state.instructors.map((instructor, index) => (
                    <div key={instructor.id} className="instructor-item">
                      <span className={index === 0 ? "status status-warning" : "status"} />
                      <div className="instructor-item-content">
                        <span className="instructor-code">{instructor.code}</span>
                        <Link to={`/wizard/instructor/${instructor.id}`}>{instructor.name}</Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default InstructorList;
