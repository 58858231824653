import React, { FunctionComponent } from "react";
import ForecastRoutes from "../../routes/Forecast";

export type UnitView = {
  levelView: any;
  subjectView: any;
  crosslistView: any;
};

export type SelectItem = {
  text: string | null;
  key: string | null;
  value: string | null;
};

export type Dashboard = {
  currentDepartmentList: any;
  currentDepartmentListSelected: SelectItem;
  currentProgramList: any;
  currentProgramListSelected: SelectItem;
  currentCurriculumList: any;
  currentCurriculumListSelected: SelectItem;
  unitView: UnitView;
  currentUnitTitle: string;
  loadingSearch: boolean;
  filterSearchState: string;
};
export interface IForecastContextInterface {
  projectId: number | string;
  dashboard: Dashboard;
  updateDashboardCurrentData: Function;
  client?: any;
  auth?: any;
  routes?: ForecastRoutes;
  filterSearchState?: Function;
  updateRoutesForecast?: Function;
}

const context = React.createContext<IForecastContextInterface | null>(null);

export const ForecastContextProvider = context.Provider;
export const ForecastContextConsumer = context.Consumer;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withForecastContext<
  P extends { forecastContext?: IForecastContextInterface },
  R = Omit<P, "forecastContext">
  //>(Component: ComponentClass<R> | FunctionComponent<R>): FunctionComponent<R> {
>(Component: any): FunctionComponent<R> {
  return function BoundComponent(props: R) {
    return (
      <ForecastContextConsumer>
        {value => <Component {...(props as any)} forecastContext={value} />}
      </ForecastContextConsumer>
    );
  };
}
