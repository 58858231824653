import * as React from "react";
import cx from "classnames";
import css from "./input.module.scss";
import Icon from "../Icon/Icon";

interface IInputProps {
  label?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  value?: any;
  disabled?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  type?: string;
  className?: string;
  error?: boolean;
  icon?: string;
  maxLength?: number;
}

const Input: React.FC<IInputProps> = (props: IInputProps) => {
  const {
    label,
    name,
    title,
    disabled,
    placeholder,
    value,
    onChange,
    onBlur,
    onKeyDown,
    type,
    className,
    error,
    icon,
    maxLength,
  } = props;

  return (
    <div className={cx(css.cntInput)}>
      {label && <label className={cx(css.cntInput_label)}>{label}</label>}
      <input
        className={cx(css.cntInput_item, className && className, error && css.foris_input__error)}
        type={`${type || "text"}`}
        id={name}
        disabled={disabled}
        name={name}
        title={title}
        placeholder={placeholder}
        autoComplete="off"
        value={value}
        maxLength={maxLength}
        onKeyDown={(e: any) => onKeyDown && onKeyDown(e)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(e)}
        onBlur={(e: any) => onBlur && onBlur(e)}
      />
      {icon && <Icon icon={icon} className={cx(css.cntInput_icon)} />}
    </div>
  );
};

export default Input;
