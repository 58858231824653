import React, { Fragment, useState, useEffect } from "react";
import { withApollo } from "react-apollo";
import { Button, Form, Message } from "@foris/foris-ui";
import { DepartmentsFilter, ProgramsFilter, CurriculumFilter } from "./index";
import { IFilterOptions } from "../models";
import { GET_UNITS } from "../queries";
import { ForecastContextConsumer } from "../../../../../context";

const Filters = (props: IFilterOptions | any) => {
  const [filterCombination, setFilterCombination] = useState({
    wasSent: false,
    isValid: true,
    message: "",
  });
  const [departmentHasEmptyError, setDepartmentHasEmptyError] = useState(false);
  const [programHasEmptyError, setProgramHasEmptyError] = useState(false);
  const [firstSearch, setFirstSearch] = useState(true);
  const [departmentReady, setDepartmentReady] = useState(false);
  const [programReady, setProgramReady] = useState(false);
  const [curriculumReady, setCurriculumReady] = useState(false);

  const searchUnits = async (
    e: any,
    department: any,
    program: any,
    departmentSchool: any,
    project: string,
  ) => {
    e && e.preventDefault();

    if (
      // SIN FILTROS SELECCIONADOS
      (!department.value && !program.value && !departmentSchool.value) ||
      // SÓLO CON ESCUELA Y SIN OTROS FILTROS SELECCIONADOS
      (department.value && !program.value && !departmentSchool.value)
    ) {
      setFilterCombination({
        wasSent: true,
        isValid: false,
        message: "Selecciona escuela y al menos una de las opciones: carrera y departamento.",
      });
      return false;
    }

    setFilterCombination({ wasSent: true, isValid: true, message: "" });

    const filters: any = {
      departmentId: department ? department.value : null,
      programId: program.value === "*" ? null : program.value,
      curriculumId: departmentSchool.value === "*" ? null : departmentSchool.value,
    };

    const result = await props.client.query({
      query: GET_UNITS,
      variables: {
        projectId: project,
        filter: filters,
      },
    });

    return result.data.project.dashboard;
  };

  const onSearch = async (e: any) => {
    props.context.updateDashboardCurrentData("loadingSearch", true);
    const result = await searchUnits(
      e,
      props.context.dashboard.currentDepartmentListSelected,
      props.context.dashboard.currentProgramListSelected,
      props.context.dashboard.currentCurriculumListSelected,
      props.context.routes.context.project,
    );
    props.context.updateDashboardCurrentData("unitView", result);
    props.context.updateDashboardCurrentData("loadingSearch", false);
    props.context.updateDashboardCurrentData("filterSearchState", "TOUCHED");
  };

  const firstData = () => {
    const dashboard = props.context?.dashboard;
    const crosslistView = dashboard?.unitView?.crosslistView;
    const levelView = dashboard?.unitView?.levelView;
    const subjectView = dashboard?.unitView?.subjectView;
    if (!crosslistView && !levelView && !subjectView && firstSearch) {
      if (departmentReady && programReady && curriculumReady && !dashboard?.loadingSearch) {
        onSearch(null);
        setFirstSearch(false);
      }
    }
  };

  useEffect(() => {
    firstData();
  }, [departmentReady, programReady, curriculumReady]);

  useEffect(() => {
    if (props.context.dashboard.currentDepartmentListSelected.value) {
      setDepartmentHasEmptyError(false);
    }
    if (props.context.dashboard.currentProgramListSelected.value) {
      setProgramHasEmptyError(false);
    }
  }, []);

  return (
    <Fragment>
      <ForecastContextConsumer>
        {context => {
          if (!context) return <p>error...</p>;
          return (
            <Fragment>
              <div className="row">
                <div className="col">
                  <div
                    style={{ padding: "2em 3em", backgroundColor: "white", marginBottom: "2em" }}
                  >
                    <Form>
                      <Form.Group className="row" style={{ marginBottom: "1em" }}>
                        <Form.Field className="col-md-4">
                          <label>Escuela</label>
                          <DepartmentsFilter
                            error={departmentHasEmptyError}
                            project={props.project}
                            context={context}
                            onCallback={(ready: boolean) => {
                              setDepartmentReady(ready);
                            }}
                          />
                        </Form.Field>
                        <Form.Field className="col-md-4">
                          <label>Carrera</label>
                          <ProgramsFilter
                            error={programHasEmptyError}
                            project={props.project}
                            context={context}
                            onCallback={(ready: boolean) => {
                              setProgramReady(ready);
                            }}
                          />
                        </Form.Field>
                        <Form.Field className="col-md-4">
                          <label>Currículo</label>
                          <CurriculumFilter
                            error={false}
                            project={props.project}
                            context={context}
                            onCallback={(ready: boolean) => {
                              setCurriculumReady(ready);
                            }}
                          />
                        </Form.Field>
                      </Form.Group>

                      <Button
                        primary
                        loading={context.dashboard.loadingSearch}
                        disabled={!context.dashboard.currentDepartmentListSelected.value}
                        onClick={(e: React.SyntheticEvent) => {
                          onSearch(e);
                        }}
                      >
                        Buscar
                      </Button>

                      <Message
                        visible={filterCombination.wasSent && filterCombination.isValid === false}
                        warning
                      >
                        <Message.Header>Debes seleccionar más opciones de búsqueda</Message.Header>
                        <p>{filterCombination.message}</p>
                      </Message>
                    </Form>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </ForecastContextConsumer>
    </Fragment>
  );
};

export default withApollo(Filters);
