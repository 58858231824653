import React from "react";
import { Link } from "react-router-dom";

import DefaultLayout from "../../common/components/layout/DefaultLayout";

import logoCatalogs from "../../assets/svg/datos.svg";
import logoProyecciones from "../../assets/svg/proyecciones.svg";
import logoSchedules from "../../assets/svg/horarios.svg";
import logoProcesos from "../../assets/svg/procesos.svg";

const MODULES = [
  {
    code: "catalogos",
    name: "Catálogos",
    logo: logoCatalogs,
    path: "/catalogs",
  },
  {
    code: "wizard",
    name: "Wizard",
    logo: logoSchedules,
    path: "/wizard",
  },
  {
    code: "forecast",
    name: "Proyecciones",
    logo: logoProyecciones,
    path: "/forecast",
  },
  {
    code: "assignation",
    name: "Asignación",
    logo: logoProcesos,
    path: "/processes",
  },
];

const Home = () => {
  return (
    <main>
      <section id="mainSection" className="main-section hero is-link is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container is-fluid">
            <div className="columns">
              <div className="column is-three-quarters">
                <div className="is-content has-background-white">
                  <h1 className="title has-text-grey">Bienvenido, Felipe</h1>
                  <h2 className="subtitle has-text-grey">Módulos Disponibles</h2>
                  <div className="section">
                    <div className="content has-background-white">
                      <ul className="module-list is-unstyled">
                        {MODULES.map(module => (
                          <li key={module.code} className="has-text-centered">
                            <Link to={module.path}>
                              <figure className="image">
                                <img
                                  src={module.logo}
                                  alt="forecast module"
                                  style={{ width: "96px", height: "96px" }}
                                />
                              </figure>
                              <p className="module-name">{module.name}</p>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="is-content has-background-white">
                  <h1 className="title has-text-grey">Actividad</h1>
                  <h2 className="subtitle has-text-grey">Sin actividad reciente</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

const Main = () => {
  return (
    <DefaultLayout>
      <Home />
    </DefaultLayout>
  );
};

export default Main;
