import React, { useState } from "react";
import { Select } from "@foris/foris-ui";
import { useQueryParam } from "../utils";

/**
 * create array with options select - format
 * @param array
 * @param code
 */
const filterFactory = (array: object[], code?: boolean) => {
  return array.map((d: any) => ({
    value: d?.id,
    key: d?.id + d?.name,
    text: code ? `${d?.code} - ${d?.name}` : d?.name,
  }));
};

/**
 * Department selector
 *  - values ​​are managed by dashboard context
 *  - deafult value by param "department"
 * @param props
 */
export const DepartmentsFilter: React.FC<{
  error: any;
  context: any;
  project: any;
  onCallback: any;
}> = (props: any) => {
  const { context, error, project, onCallback } = props;
  const [changeDefault, setChangeDefault] = useState(false);
  const options = filterFactory(project.departments, true);
  const departmentParam = useQueryParam()?.get("department");
  let defaultDepartment = context?.dashboard?.currentDepartmentListSelected?.value;

  const changeValue = (value: any) => {
    const newProgramList = project.programs.filter((item: any) => {
      return item.department.id === value.value;
    });
    context.updateDashboardCurrentData("currentDepartmentListSelected", {
      selectedValue: value,
      newProgramList: newProgramList,
    });
    onCallback(true);
  };

  if (departmentParam && departmentParam !== defaultDepartment && !changeDefault) {
    const optionParam = options.find(value => value.value === departmentParam);
    if (optionParam) {
      defaultDepartment = optionParam.value;
      changeValue(optionParam);
      setChangeDefault(true);
    }
  }

  return (
    <Select
      search
      className="ellipsisText"
      placeholder="Selecciona una escuela"
      noResultsMessage={"No se han encontrado resultados"}
      options={options}
      error={error}
      value={defaultDepartment}
      onChange={(e: React.SyntheticEvent, filterValue: any) => {
        changeValue(filterValue);
        onCallback(false);
      }}
    />
  );
};

/**
 * Program selector
 *  - values ​​are managed by dashboard context
 *  - deafult value by param "program"
 * @param props
 */
export const ProgramsFilter: React.FC<{
  error: any;
  context: any;
  project: any;
  onCallback: any;
}> = (props: any) => {
  const { context, error, onCallback } = props;
  const [changeDefault, setChangeDefault] = useState(false);
  const rawOptions = context.dashboard.currentProgramList;
  const options: any = filterFactory(rawOptions, true); // selector options
  const currentDepartment = context?.dashboard?.currentDepartmentListSelected?.value; // current department
  let currentProgram = context?.dashboard?.currentProgramListSelected?.value; // current program
  const programParam = useQueryParam()?.get("program"); // program param

  // Set options - all programs
  // TODO: uncomment when can select all programs view is finish
  if (options[0] && options[0].key && options[0].key !== "*__program") {
    options.unshift({ value: "*", key: "*__program", text: "Todas las carreras" });
  } else if (options.length === 0) {
    options.push({ value: "*", key: "*__program", text: "Todas las carreras" });
  }

  // Get default value of program selector
  if (currentDepartment && programParam && currentProgram !== programParam && !changeDefault) {
    const optionParam = options.find((value: any) => value.value === programParam);
    if (optionParam) {
      currentProgram = optionParam.value;
      context.updateDashboardCurrentData("currentProgramListSelected", optionParam);
      setChangeDefault(true);
    }
    onCallback(true);
  }
  if (!programParam || currentProgram === "*") onCallback(true);

  return (
    <Select
      search
      placeholder="Selecciona una carrera"
      noResultsMessage={"No se han encontrado resultados"}
      options={options}
      error={error}
      className="ellipsisText"
      disabled={!context.dashboard.currentDepartmentListSelected.value}
      value={currentProgram}
      // TODO: uncomment when can select all programs view is finish
      // value={props.context.dashboard.currentProgramListSelected.value || "*"}
      onChange={(e: React.SyntheticEvent, filterValue: any) => {
        context.updateDashboardCurrentData("currentProgramListSelected", filterValue);
      }}
    />
  );
};

export const CurriculumFilter: React.FC<{
  error: any;
  context: any;
  project: any;
  onCallback: any;
}> = (props: any) => {
  const { context, error, project, onCallback } = props;
  const [changeDefault, setChangeDefault] = useState(false);
  const currentDepartment = context?.dashboard?.currentDepartmentListSelected?.value; // current department
  const currentProgram = context?.dashboard?.currentProgramListSelected?.value; // current department
  let defaultCurriculum = context?.dashboard?.currentCurriculumListSelected?.value; // current curriculum
  const curriculumParam = useQueryParam()?.get("curriculum");

  // Generate options
  const rawOptions = project?.curriculums;
  const validateProgram = !currentProgram || currentProgram === "*";
  const optionsFilter: any = rawOptions
    ? rawOptions.filter((item: any) => currentProgram === item.program.id)
    : null;
  const optionEmpty = [{ value: "*", key: "*__department", text: "Sin datos" }];
  const options =
    validateProgram && rawOptions
      ? filterFactory(rawOptions)
      : rawOptions
      ? filterFactory(optionsFilter)
      : optionEmpty;
  // All options
  if (options[0] && options[0].key !== "*__department")
    options.unshift({ value: "*", key: "*__department", text: "Todos los currículos" });

  if (
    currentDepartment &&
    currentProgram &&
    currentProgram !== "*" &&
    curriculumParam &&
    curriculumParam !== defaultCurriculum &&
    !changeDefault
  ) {
    const optionParam = options.find((value: any) => value.value === curriculumParam);
    if (optionParam) {
      defaultCurriculum = curriculumParam;
      context.updateDashboardCurrentData("currentCurriculumListSelected", optionParam);
      setChangeDefault(true);
    }
    onCallback(true);
  }

  if (!curriculumParam || defaultCurriculum === "*") onCallback(true);

  return (
    <Select
      search
      placeholder="Selecciona un currículo"
      noResultsMessage={"No se han encontrado resultados"}
      options={options}
      className="ellipsisText"
      error={error}
      disabled={!currentDepartment || options === optionEmpty}
      value={defaultCurriculum}
      onChange={(e: React.SyntheticEvent, filterValue: any) => {
        context.updateDashboardCurrentData("currentCurriculumListSelected", filterValue);
      }}
    />
  );
};
