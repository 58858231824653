import React, { useEffect } from "react";
import { useTable, useSortBy, useRowSelect, Column } from "react-table";
import cx from "classnames";
import * as utils from "./utils";
import css from "./table.module.scss";

type Data = object;

type Props = {
  columns: Column<Data>[];
  data: Data[];
  sort?: boolean;
  onSelectedRows?: any;
  initSelectedRowIds?: any;
  updateImplementedData?: any;
  updateImplementAllData?: any;
};

const Table = (props: Props) => {
  const { columns, data, initSelectedRowIds, onSelectedRows } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable<Data>(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: initSelectedRowIds ? initSelectedRowIds : {},
      },
      updateImplementedData: utils.updateImplementedData,
      updateImplementAllData: utils.updateImplementedAllData,
    },
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [...columns]);
    },
  );

  useEffect(() => {
    if (onSelectedRows) onSelectedRows(selectedFlatRows, selectedRowIds);
  }, [selectedFlatRows, selectedRowIds, onSelectedRows]);

  return (
    <section className={cx(css.cntTable, "container-row")} {...getTableProps()}>
      <section className={cx(css.cntHeader, "container-row")}>
        {headerGroups.map((headerGroup: any, index: number) => (
          <ul
            key={index}
            className={cx(css.headerList, "container-row", "row--between", "row_align--center")}
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column: any, index: number) => (
              <li
                key={index}
                className={cx(css.headerList_item)}
                style={{
                  width: column.width,
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth,
                }}
                {...column.getHeaderProps(props.sort ? column.getSortByToggleProps() : 0)}
              >
                {column.render("Header")}
                {props.sort && (
                  <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                )}
              </li>
            ))}
          </ul>
        ))}
      </section>
      <section className={cx(css.contentTable, "container-row")} {...getTableBodyProps()}>
        {rows.map((row: any, index: number) => {
          prepareRow(row);
          return (
            <ul key={index} className={cx(css.contentList, "container-row")} {...row.getRowProps()}>
              {row.cells.map((cell: any, index: number) => {
                return (
                  <li
                    key={index}
                    className={cx(css.contentList_item)}
                    style={{
                      width: cell.column.width,
                      minWidth: cell.column.minWidth,
                      maxWidth: cell.column.maxWidth,
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </section>
    </section>
  );
};

export default Table;
