import React, { Component } from "react";
import { Link } from "react-router-dom";
import Button from "./components/Button";

class Home extends Component {
  render() {
    return (
      <div className="testFragment">
        <p>
          <strong>Home page</strong> inside on <strong>catalogs</strong> module.
        </p>
        <Button />
        <Link to="/catalogs/curriculums">
          <br />
          Link to <strong>Currículum</strong>
        </Link>
      </div>
    );
  }
}

export default Home;
