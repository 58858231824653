import { useLocation } from "react-router-dom";

/**
 * hook useLocation - get optionals params
 */
export const useQueryParam = () => new URLSearchParams(useLocation().search);

const generateStringParam = (
  department: string,
  program: string,
  curriculum: string,
  tab = "0",
) => {
  const params = [];
  department && params.push(`department=${department}`);
  program && params.push(`program=${program}`);
  curriculum && params.push(`curriculum=${curriculum}`);
  params.push(`tab=${tab}`);
  let stringFilter = "";
  if (params.length > 0) {
    params.forEach(value => {
      stringFilter = stringFilter ? `${stringFilter}&${value}` : `${value}`;
    });
  }
  return `?${stringFilter}`;
};

/**
 * Return string with params filter
 */
export const useStringFilterParams = () => {
  const department = useQueryParam()?.get("department");
  const program = useQueryParam()?.get("program");
  const curriculum = useQueryParam()?.get("curriculum");
  const tab = useQueryParam()?.get("tab");
  return generateStringParam(department, program, curriculum, tab);
};

/**
 * Return string with filter or params selected
 */
export const useGenerateStringFilter = (
  department: string,
  program: string,
  curriculum: string,
  tab = "0",
) => {
  return generateStringParam(department, program, curriculum, tab);
};
