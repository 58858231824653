import gql from "graphql-tag";

const EDIT_CONFIRM_CROSSLIST = gql`
  mutation editConfirmCrosslist($unitId: ID!, $confirmed: Boolean) {
    confirmUnit(unitId: $unitId, confirmed: $confirmed) {
      status {
        code
        msg
      }
      unit {
        id
        label
        crosslistConfirmation
        demands {
          checked
        }
      }
    }
  }
`;

export default EDIT_CONFIRM_CROSSLIST;
