import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { moduleRoutes } from "./routes";

import DefaultLayout from "../../common/components/layout/DefaultLayout";

const WizardOutlet = () => (
  <Switch>
    {moduleRoutes.map(route => (
      <Route key={route.path} path={route.path} component={route.component} />
    ))}
  </Switch>
);

const Wizard = () => (
  <DefaultLayout>
    <BrowserRouter>
      <WizardOutlet />
    </BrowserRouter>
  </DefaultLayout>
);

export default Wizard;
