export interface IContext {
  workspace: string;
  workspaceName?: string;
  scenario: string;
  scenarioName?: string;
  project: string;
  projectName?: string;
}

export type Permissions = "public" | "private" | "onlyAdmin";

export interface IRouteType {
  path: string;
  component?: any;
  name: string;
  permissions: Permissions;
}

export default class Routes {
  context: IContext = null;
  base = "";
  name = "";
  constructor(context: IContext, base: string, name: string) {
    this.context = context;
    this.base = base;
    this.name = name;
  }

  /**
   * set value of context
   * @param context new context
   */
  setContext = (context: IContext) => {
    this.context = context;
  };

  /**
   * return value of selected context
   */
  getContext = (): IContext => {
    return this.context;
  };

  /**
   * return url of selected context to concat with other url
   */
  getStringContext = (): string => {
    return `${this.context.workspace}/${this.context.scenario}/${this.context.project}`;
  };

  /**
   * get type of url (react-router) context
   */
  getTypeRouteContext = (): string => {
    return ":workspace?/:scenario?/:project?";
  };

  routeType = (page: string, component: any, permissions: Permissions) => {
    const projectStatus: IRouteType = {
      path: `${this.base}${page ? `/${page}` : ""}`,
      component: component,
      name: `${this.name}${page ? `/${page}` : ""}`,
      permissions: permissions,
    };
    return projectStatus;
  };

  routeTypeWithContext = (
    page: string,
    component: any,
    permissions: Permissions,
    params?: string,
  ) => {
    const route = this.routeType(page, component, permissions);
    route.path = `${route.path}/${this.getTypeRouteContext()}${params || ""}`;
    return route;
  };
}
