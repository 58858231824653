const icons = {
  IcoMoonType: "selection",
  icons: [
    {
      icon: {
        paths: [
          "M32 238.116c0-75.951 61.594-137.544 137.544-137.544h308.17c27.351 0 49.523 22.173 49.523 49.523s-22.173 49.523-49.523 49.523h-308.17c-21.248 0-38.497 17.248-38.497 38.497v616.337c0 21.25 17.248 38.503 38.497 38.503h616.337c21.25 0 38.503-17.253 38.503-38.503v-308.167c0-27.351 22.169-49.523 49.522-49.523s49.522 22.173 49.522 49.523v308.167c0 75.956-61.591 137.547-137.547 137.547h-616.337c-75.951 0-137.544-61.591-137.544-137.547v-616.337z",
          "M874.901 138.454c-17.904-16.28-47.013-16.28-64.916 0l-427.536 388.667-21.651 78.699 86.57-19.682 427.533-388.667c17.911-16.28 17.911-42.739 0-59.017zM736.681 71.814c58.388-53.084 153.134-53.084 211.522 0 58.395 53.084 58.395 139.214 0 192.297l-437.682 397.895c-6.642 6.038-14.963 10.322-24.075 12.394l-184.321 41.907c-17.664 4.015-36.352-0.688-49.227-12.394s-18.052-28.694-13.633-44.752l46.098-167.565c2.279-8.284 6.991-15.849 13.633-21.887l437.687-397.895z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 16,
        tags: ["edit-new"],
      },
      attrs: [{}, {}],
      properties: {
        order: 92,
        id: 22,
        name: "editnew",
        prevSize: 16,
        code: 59671,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          "M207.007 207.010c-24.061 0-43.569 19.507-43.569 43.569v609.984c0 24.061 19.507 43.57 43.569 43.57h609.984c24.061 0 43.57-19.507 43.57-43.57v-609.984c0-24.061-19.507-43.569-43.57-43.569h-609.984zM76.297 250.579c0-72.19 58.523-130.71 130.71-130.71h609.984c72.189 0 130.709 58.523 130.709 130.71v609.984c0 72.189-58.522 130.709-130.709 130.709h-609.984c-72.19 0-130.71-58.522-130.71-130.709v-609.984z",
          "M686.284 32.727c24.063 0 43.57 19.508 43.57 43.57v174.283c0 24.063-19.507 43.57-43.57 43.57s-43.57-19.507-43.57-43.57v-174.283c0-24.063 19.507-43.57 43.57-43.57z",
          "M337.72 32.727c24.063 0 43.57 19.508 43.57 43.57v174.283c0 24.063-19.507 43.57-43.57 43.57s-43.57-19.507-43.57-43.57v-174.283c0-24.063 19.507-43.57 43.57-43.57z",
          "M76.297 424.86c0-24.063 19.508-43.57 43.571-43.57h784.266c24.063 0 43.57 19.507 43.57 43.57s-19.507 43.57-43.57 43.57h-784.266c-24.063 0-43.571-19.507-43.571-43.57z",
        ],
        attrs: [{}, {}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["calendar"],
        grid: 16,
      },
      attrs: [{}, {}, {}, {}],
      properties: {
        order: 93,
        id: 0,
        name: "calendar",
        prevSize: 16,
        code: 59648,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 1,
    },
    {
      icon: {
        paths: [
          "M44.435 983.195l31.030-274.557 498.45-501.686 235.96 242.786-480.45 485.706-284.99 47.75zM197.455 719.003l-94.031 13.921-16.281 175.168 40.669 33.708 189.51-20.901v-95.917l-90.591-22.835-29.277-83.144z",
          "M573.959 198.525c2.26 0.010 4.421 0.933 5.997 2.555l235.96 242.786c3.197 3.29 3.176 8.536-0.052 11.801l-480.45 485.706c-1.245 1.256-2.855 2.091-4.599 2.385l-284.442 47.64c-0.772 0.184-1.581 0.258-2.415 0.214-4.463-0.249-7.952-3.944-7.952-8.414v-0.017c0-0.389 0.025-0.772 0.078-1.152l31.002-274.335c0.214-1.886 1.057-3.645 2.395-4.992l498.451-501.685c1.592-1.605 3.764-2.501 6.024-2.489l0.005-0.002zM54.064 973.021l255.378-42.773-180.706 19.928c-2.269 0.249-4.544-0.429-6.303-1.886l-40.669-33.708c-2.144-1.776-3.271-4.497-3.013-7.267l16.281-175.168c0.359-3.867 3.316-6.988 7.157-7.557l94.029-13.921c3.992-0.592 7.843 1.73 9.183 5.536l27.775 78.875 86.204 21.732c3.742 0.945 6.369 4.311 6.369 8.173v95.917c0 3.117-1.702 5.874-4.269 7.329l3.865-0.647 472.726-477.898-224.225-230.712-490.34 493.524-29.442 260.526zM111.203 740.292l-15.258 164.147 34.495 28.591 178.457-19.679v-81.803l-84.227-21.233c-2.74-0.692-4.95-2.708-5.889-5.373l-26.968-76.587-80.611 11.936z",
          "M855.348 63.252l104.65 108.982c18.503 19.268 28.566 45.131 27.97 71.891s-11.799 52.231-31.143 70.808l-121.569 116.736-244.185-254.295 121.569-116.736c19.345-18.576 45.249-28.738 72.011-28.246s52.193 11.592 70.696 30.86h0.002z",
        ],
        attrs: [],
        isMulticolor: false,
        isMulticolor2: false,
        colorPermutations: {},
        tags: ["pencil"],
        grid: 16,
      },
      attrs: [],
      properties: {
        order: 94,
        id: 1,
        name: "pencil",
        prevSize: 16,
        code: 59649,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 2,
    },
    {
      icon: {
        paths: [
          "M381.914 510.925c0 31.696-25.695 57.39-57.39 57.39s-57.39-25.695-57.39-57.39c0-31.696 25.695-57.39 57.39-57.39s57.39 25.695 57.39 57.39z",
          "M568.54 510.925c0 31.696-25.695 57.39-57.39 57.39s-57.39-25.695-57.39-57.39c0-31.696 25.695-57.39 57.39-57.39s57.39 25.695 57.39 57.39z",
          "M755.169 510.925c0 31.696-25.695 57.39-57.39 57.39s-57.39-25.695-57.39-57.39c0-31.696 25.695-57.39 57.39-57.39s57.39 25.695 57.39 57.39z",
          "M512 119.869c216.567 0 392.133 175.562 392.133 392.132 0 0.135 0 0.27 0.002 0.407-0.22 216.38-175.698 391.726-392.135 391.726-216.572 0-392.132-175.563-392.132-392.133s175.565-392.132 392.132-392.132zM991.272 511.554c-0.241-264.491-214.727-478.826-479.272-478.826-264.694 0-479.273 214.577-479.273 479.273s214.575 479.272 479.273 479.272c264.698 0 479.274-214.578 479.274-479.272 0-0.149 0-0.298-0.002-0.447z",
        ],
        attrs: [{}, {}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["dots-circle"],
        grid: 16,
      },
      attrs: [{}, {}, {}, {}],
      properties: {
        order: 95,
        id: 2,
        name: "dots-circle",
        prevSize: 16,
        code: 59650,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 3,
    },
    {
      icon: {
        paths: [
          "M512 33.597c52.843 0 95.681 42.838 95.681 95.681v382.722c0 52.842-42.838 95.679-95.681 95.679s-95.681-42.837-95.681-95.679v-382.722c0-52.843 42.838-95.681 95.681-95.681z",
          "M416.319 894.727c0-52.842 42.838-95.679 95.681-95.679h0.96c52.843 0 95.681 42.837 95.681 95.679s-42.838 95.679-95.681 95.679h-0.96c-52.843 0-95.681-42.837-95.681-95.679z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["alert"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 96,
        id: 3,
        name: "alert",
        prevSize: 16,
        code: 59651,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 4,
    },
    {
      icon: {
        paths: [
          "M47.999 512c0-25.626 20.774-46.402 46.404-46.402h835.197c25.626 0 46.402 20.774 46.402 46.402s-20.774 46.402-46.402 46.402h-835.197c-25.626 0-46.404-20.774-46.404-46.402v0z",
          "M47.999 233.602c0-25.626 20.774-46.402 46.404-46.402h835.197c25.626 0 46.402 20.774 46.402 46.402s-20.774 46.402-46.402 46.402h-835.197c-25.626 0-46.404-20.774-46.404-46.402v0z",
          "M47.999 790.399c0-25.626 20.774-46.402 46.404-46.402h835.197c25.626 0 46.402 20.774 46.402 46.402 0 25.626-20.774 46.401-46.402 46.401h-835.197c-25.626 0-46.404-20.774-46.404-46.401v0z",
        ],
        attrs: [{}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["menu"],
        grid: 16,
      },
      attrs: [{}, {}, {}],
      properties: {
        order: 97,
        id: 4,
        name: "menu",
        prevSize: 16,
        code: 59652,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 5,
    },
    {
      icon: {
        paths: [
          "M964.471 189.443c20.293 20.294 20.293 53.197 0 73.491l-571.625 571.624c-20.293 20.293-53.195 20.293-73.491 0l-259.827-259.828c-20.294-20.293-20.294-53.195 0-73.491 20.294-20.293 53.197-20.293 73.491 0l223.081 223.082 534.879-534.878c20.296-20.294 53.197-20.294 73.491 0z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["check"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 98,
        id: 5,
        name: "check",
        prevSize: 16,
        code: 59653,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 6,
    },
    {
      icon: {
        paths: [
          "M558.005 753.188c-25.408 25.411-66.603 25.411-92.011 0l-390.367-390.367c-25.408-25.408-25.408-66.603 0-92.011s66.603-25.408 92.010 0l344.362 344.361 344.363-344.361c25.408-25.408 66.603-25.408 92.011 0s25.408 66.603 0 92.011l-390.368 390.367z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["arrow-down"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 99,
        id: 6,
        name: "arrow-down",
        prevSize: 16,
        code: 59654,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 7,
    },
    {
      icon: {
        paths: [
          "M765.899 463.571c26.747 26.747 26.747 70.11 0 96.856l-410.939 410.939c-26.747 26.75-70.112 26.75-96.859 0-26.747-26.747-26.747-70.11 0-96.856l362.509-362.513-362.509-362.508c-26.747-26.747-26.747-70.112 0-96.859s70.112-26.747 96.859 0l410.939 410.941z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["arrow-right"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 100,
        id: 7,
        name: "arrow-right",
        prevSize: 16,
        code: 59655,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 8,
    },
    {
      icon: {
        paths: [
          "M258.1 560.428c-26.747-26.747-26.747-70.11 0-96.86l410.939-410.938c26.75-26.747 70.113-26.747 96.86 0s26.747 70.112 0 96.86l-362.509 362.508 362.509 362.51c26.747 26.75 26.747 70.113 0 96.86-26.747 26.75-70.11 26.75-96.86 0l-410.939-410.939z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["arrow-left"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 101,
        id: 8,
        name: "arrow-left",
        prevSize: 16,
        code: 59656,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 9,
    },
    {
      icon: {
        paths: [
          "M466.167 271.714c25.312-25.313 66.354-25.313 91.666 0l388.907 388.907c25.312 25.312 25.312 66.354 0 91.666s-66.354 25.312-91.666 0l-343.074-343.074-343.073 343.074c-25.313 25.312-66.353 25.312-91.666 0s-25.313-66.354 0-91.666l388.906-388.907z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["arrow-up"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 102,
        id: 9,
        name: "arrow-up",
        prevSize: 16,
        code: 59657,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 10,
    },
    {
      icon: {
        paths: [
          "M511.998 119.868c216.567 0 392.133 175.564 392.133 392.131 0 0.137 0 0.272 0.002 0.407-0.218 216.382-175.698 391.726-392.135 391.726-216.57 0-392.131-175.563-392.131-392.133 0-216.567 175.564-392.131 392.131-392.131zM991.272 511.552c-0.243-264.49-214.73-478.825-479.274-478.825-264.694 0-479.272 214.576-479.272 479.272s214.574 479.274 479.272 479.274c264.698 0 479.277-214.581 479.277-479.274 0-0.149-0.002-0.298-0.002-0.447z",
          "M511.998 207.008c24.063 0 43.572 19.508 43.572 43.57v234.493l150.197 75.098c21.522 10.761 30.247 36.932 19.486 58.456-10.761 21.522-36.934 30.247-58.456 19.486l-174.283-87.139c-14.762-7.381-24.086-22.468-24.086-38.972v-261.422c0-24.062 19.507-43.57 43.57-43.57z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["clock"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 103,
        id: 10,
        name: "clock",
        prevSize: 16,
        code: 59658,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 11,
    },
    {
      icon: {
        paths: [
          "M973.912 90.463c17.236 15.673 18.975 42.179 3.929 59.969l-336.004 397.325v353.759c0 15-7.766 28.93-20.524 36.815-12.761 7.886-28.693 8.604-42.109 1.895l-173.117-86.557c-14.664-7.33-23.925-22.316-23.925-38.71v-267.201l-336.004-397.323c-10.881-12.866-13.304-30.877-6.213-46.162s22.41-25.064 39.259-25.064h865.165c10.547-0.104 21.147 3.625 29.54 11.256zM172.487 165.766l286.002 338.195c6.607 7.814 10.232 17.715 10.232 27.948v256.299l86.56 43.279v-299.578c0-10.232 3.625-20.133 10.231-27.946l286.002-338.197h-679.026z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["filter"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 104,
        id: 11,
        name: "filter",
        prevSize: 16,
        code: 59659,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 12,
    },
    {
      icon: {
        paths: [
          "M279.655 233.186c0-25.664 20.806-46.47 46.47-46.47h604.097c25.664 0 46.469 20.805 46.469 46.47s-20.804 46.469-46.469 46.469h-604.097c-25.664 0-46.47-20.804-46.47-46.469z",
          "M279.655 512c0-25.664 20.806-46.469 46.47-46.469h604.097c25.664 0 46.469 20.804 46.469 46.469s-20.804 46.469-46.469 46.469h-604.097c-25.664 0-46.47-20.804-46.47-46.469z",
          "M279.655 790.814c0-25.664 20.806-46.469 46.47-46.469h604.097c25.664 0 46.469 20.804 46.469 46.469s-20.804 46.469-46.469 46.469h-604.097c-25.664 0-46.47-20.804-46.47-46.469z",
          "M47.31 233.186c0-25.664 20.805-46.47 46.47-46.47h0.464c25.664 0 46.469 20.805 46.469 46.47s-20.805 46.469-46.469 46.469h-0.464c-25.664 0-46.47-20.804-46.47-46.469z",
          "M47.31 512c0-25.664 20.805-46.469 46.47-46.469h0.464c25.664 0 46.469 20.804 46.469 46.469s-20.805 46.469-46.469 46.469h-0.464c-25.664 0-46.47-20.804-46.47-46.469z",
          "M47.31 790.814c0-25.664 20.805-46.469 46.47-46.469h0.464c25.664 0 46.469 20.804 46.469 46.469s-20.805 46.469-46.469 46.469h-0.464c-25.664 0-46.47-20.804-46.47-46.469z",
        ],
        attrs: [{}, {}, {}, {}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["list"],
        grid: 16,
      },
      attrs: [{}, {}, {}, {}, {}, {}],
      properties: {
        order: 105,
        id: 12,
        name: "list",
        prevSize: 16,
        code: 59660,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 13,
    },
    {
      icon: {
        paths: [
          "M511.973 992c-16.558 0-33.116-7.605-45.189-19.86-11.453-12.921-18.49-30.639-18.49-48.285 0-17.793 7.038-35.439 18.49-48.432 24.768-25.176 66.232-25.176 90.447 0 11.454 12.993 18.489 30.639 18.489 48.432 0 17.646-7.035 35.364-18.489 48.285-12.21 12.255-28.7 19.86-45.258 19.86z",
          "M511.965 818.576c-35.185 0-63.679-30.492-63.679-68.145v-195.942c0-37.653 28.493-68.145 63.679-68.145 81.962 0 148.607-71.319 148.607-159.027s-66.645-159.102-148.607-159.102-148.608 71.393-148.608 159.102c0 37.653-28.493 68.145-63.679 68.145s-63.679-30.567-63.679-68.145c0-162.867 123.771-295.317 275.965-295.317s276.035 132.45 276.035 295.317c0 139.389-90.723 256.629-212.355 287.418v135.771c0 37.578-28.494 68.070-63.68 68.070z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["question"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 106,
        id: 13,
        name: "question",
        prevSize: 16,
        code: 59661,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 14,
    },
    {
      icon: {
        paths: [
          "M177.115 129.278c-26.422 0-47.839 21.418-47.839 47.839v669.765c0 26.419 21.418 47.84 47.839 47.84h191.364c26.422 0 47.84 21.418 47.84 47.84s-21.418 47.84-47.84 47.84h-191.364c-79.265 0-143.52-64.257-143.52-143.519v-669.765c0-79.264 64.256-143.52 143.52-143.52h191.364c26.422 0 47.84 21.419 47.84 47.841s-21.418 47.84-47.84 47.84h-191.364z",
          "M669.535 238.968c18.682-18.683 48.974-18.683 67.657 0l239.201 239.201c18.682 18.682 18.682 48.974 0 67.657l-239.201 239.201c-18.682 18.682-48.974 18.682-67.657 0s-18.682-48.974 0-67.657l205.373-205.373-205.373-205.373c-18.682-18.682-18.682-48.974 0-67.657z",
          "M320.639 511.997c0-26.422 21.418-47.84 47.84-47.84h574.086c26.422 0 47.84 21.418 47.84 47.84s-21.418 47.84-47.84 47.84h-574.086c-26.422 0-47.84-21.418-47.84-47.84z",
        ],
        attrs: [{}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["log-out"],
        grid: 16,
      },
      attrs: [{}, {}, {}],
      properties: {
        order: 107,
        id: 14,
        name: "log-out",
        prevSize: 16,
        code: 59662,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 15,
    },
    {
      icon: {
        paths: [
          "M0 512c0-35.346 28.654-64 64-64h896c35.346 0 64 28.654 64 64s-28.654 64-64 64h-896c-35.346 0-64-28.654-64-64z",
        ],
        attrs: [{}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["minus"],
        grid: 16,
      },
      attrs: [{}],
      properties: {
        order: 108,
        id: 15,
        name: "minus",
        prevSize: 16,
        code: 59663,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 16,
    },
    {
      icon: {
        paths: [
          "M512.003 33c33.068 0 59.874 26.807 59.874 59.875v838.251c0 33.068-26.806 59.874-59.874 59.874s-59.874-26.806-59.874-59.874v-838.251c0-33.068 26.806-59.875 59.874-59.875z",
          "M32.999 512.004c0-33.068 26.807-59.874 59.875-59.874h838.252c33.068 0 59.874 26.806 59.874 59.874s-26.806 59.874-59.874 59.874h-838.252c-33.068 0-59.875-26.806-59.875-59.874z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["plus"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 109,
        id: 16,
        name: "plus",
        prevSize: 16,
        code: 59664,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 17,
    },
    {
      icon: {
        paths: [
          "M464.039 128.319c-185.412 0-335.72 150.308-335.72 335.72 0 185.414 150.306 335.722 335.72 335.722s335.722-150.31 335.722-335.722c0-185.414-150.307-335.72-335.722-335.72zM32.399 464.039c0-238.386 193.252-431.64 431.64-431.64s431.643 193.252 431.643 431.64c0 238.387-193.251 431.643-431.643 431.643-238.388 0-431.64-193.254-431.64-431.643z",
          "M701.102 701.1c18.729-18.729 49.094-18.729 67.824 0l208.626 208.626c18.731 18.731 18.731 49.097 0 67.826-18.729 18.731-49.094 18.731-67.824 0l-208.626-208.626c-18.731-18.729-18.731-49.094 0-67.826z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["search"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 110,
        id: 17,
        name: "search",
        prevSize: 16,
        code: 59665,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 18,
    },
    {
      icon: {
        paths: [
          "M511.996 119.868c216.567 0 392.133 175.564 392.133 392.131 0 0.137 0 0.272 0.002 0.407-0.218 216.382-175.698 391.726-392.135 391.726-216.57 0-392.131-175.563-392.131-392.133 0-216.567 175.564-392.131 392.131-392.131zM991.271 511.552c-0.243-264.49-214.73-478.825-479.274-478.825-264.694 0-479.272 214.576-479.272 479.272s214.574 479.274 479.272 479.274c264.698 0 479.277-214.581 479.277-479.274 0-0.149-0.002-0.298-0.002-0.447z",
          "M173.148 173.15c17.015-17.015 44.602-17.015 61.618 0l616.087 616.085c17.015 17.017 17.015 44.603 0 61.618-17.017 17.017-44.603 17.017-61.618 0l-616.086-616.085c-17.015-17.015-17.015-44.602 0-61.618z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["block"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 111,
        id: 18,
        name: "block",
        prevSize: 16,
        code: 59666,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 19,
    },
    {
      icon: {
        paths: [
          "M76.297 250.579c0-24.063 19.508-43.571 43.57-43.571h784.266c24.063 0 43.57 19.508 43.57 43.571s-19.507 43.57-43.57 43.57h-784.266c-24.063 0-43.57-19.507-43.57-43.57z",
          "M424.86 119.871c-24.065 0-43.572 19.507-43.572 43.569v43.569h261.424v-43.569c0-24.063-19.507-43.569-43.57-43.569h-174.282zM729.852 207.009h87.142c24.063 0 43.57 19.507 43.57 43.57v609.984c0 72.189-58.522 130.709-130.709 130.709h-435.703c-72.19 0-130.71-58.522-130.71-130.709v-609.984c0-24.063 19.508-43.57 43.57-43.57h87.137v-43.569c0-72.19 58.52-130.71 130.711-130.71h174.282c72.189 0 130.709 58.52 130.709 130.71v43.569zM250.582 294.149v566.414c0 24.061 19.507 43.57 43.569 43.57h435.703c24.061 0 43.57-19.507 43.57-43.57v-566.414h-522.841z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["trash"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 112,
        id: 19,
        name: "trash",
        prevSize: 16,
        code: 59667,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 20,
    },
    {
      icon: {
        paths: [
          "M80.358 847.723c0-132.439 107.362-239.801 239.801-239.801h383.681c132.441 0 239.803 107.364 239.803 239.801v95.918c0 26.488-21.474 47.962-47.962 47.962s-47.959-21.474-47.959-47.962v-95.918c0-79.462-64.418-143.88-143.882-143.88h-383.681c-79.464 0-143.881 64.416-143.881 143.88v95.918c0 26.488-21.472 47.962-47.96 47.962s-47.96-21.474-47.96-47.962v-95.918z",
          "M512.005 128.317c-79.465 0-143.882 64.417-143.882 143.881s64.418 143.882 143.882 143.882c79.465 0 143.88-64.416 143.88-143.88v-0.002c0-79.464-64.418-143.881-143.88-143.881zM272.202 272.198c0-132.438 107.361-239.801 239.803-239.801 132.439 0 239.801 107.363 239.801 239.801v0.002c0 132.441-107.364 239.801-239.801 239.801-132.441 0-239.803-107.364-239.803-239.803z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["user"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 113,
        id: 20,
        name: "user",
        prevSize: 16,
        code: 59668,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 21,
    },
    {
      icon: {
        paths: [
          "M969.736 54.264c26.651 26.652 26.651 69.863 0 96.515l-818.956 818.957c-26.652 26.651-69.863 26.651-96.515 0s-26.652-69.864 0-96.515l818.956-818.956c26.651-26.652 69.864-26.652 96.515 0z",
          "M54.265 54.264c26.652-26.652 69.863-26.652 96.515 0l818.956 818.956c26.651 26.651 26.651 69.864 0 96.515s-69.864 26.651-96.515 0l-818.956-818.957c-26.652-26.652-26.652-69.863 0-96.515z",
        ],
        attrs: [{}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ["close"],
        grid: 16,
      },
      attrs: [{}, {}],
      properties: {
        order: 114,
        id: 21,
        name: "close",
        prevSize: 16,
        code: 59669,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 22,
    },
    {
      icon: {
        paths: [
          "M64 86.4c0-12.37 10.030-22.4 22.4-22.4h179.2c12.37 0 22.4 10.030 22.4 22.4v179.2c0 12.37-10.030 22.4-22.4 22.4h-179.2c-12.37 0-22.4-10.030-22.4-22.4v-179.2z",
          "M64 422.4c0-12.371 10.030-22.4 22.4-22.4h179.2c12.37 0 22.4 10.029 22.4 22.4v179.2c0 12.371-10.030 22.4-22.4 22.4h-179.2c-12.37 0-22.4-10.029-22.4-22.4v-179.2z",
          "M64 758.4c0-12.371 10.030-22.4 22.4-22.4h179.2c12.37 0 22.4 10.029 22.4 22.4v179.2c0 12.371-10.030 22.4-22.4 22.4h-179.2c-12.37 0-22.4-10.029-22.4-22.4v-179.2z",
          "M400 86.4c0-12.37 10.029-22.4 22.4-22.4h179.2c12.371 0 22.4 10.030 22.4 22.4v179.2c0 12.37-10.029 22.4-22.4 22.4h-179.2c-12.371 0-22.4-10.030-22.4-22.4v-179.2z",
          "M400 422.4c0-12.371 10.029-22.4 22.4-22.4h179.2c12.371 0 22.4 10.029 22.4 22.4v179.2c0 12.371-10.029 22.4-22.4 22.4h-179.2c-12.371 0-22.4-10.029-22.4-22.4v-179.2z",
          "M400 758.4c0-12.371 10.029-22.4 22.4-22.4h179.2c12.371 0 22.4 10.029 22.4 22.4v179.2c0 12.371-10.029 22.4-22.4 22.4h-179.2c-12.371 0-22.4-10.029-22.4-22.4v-179.2z",
          "M736 86.4c0-12.37 10.029-22.4 22.4-22.4h179.2c12.371 0 22.4 10.030 22.4 22.4v179.2c0 12.37-10.029 22.4-22.4 22.4h-179.2c-12.371 0-22.4-10.030-22.4-22.4v-179.2z",
          "M736 422.4c0-12.371 10.029-22.4 22.4-22.4h179.2c12.371 0 22.4 10.029 22.4 22.4v179.2c0 12.371-10.029 22.4-22.4 22.4h-179.2c-12.371 0-22.4-10.029-22.4-22.4v-179.2z",
          "M736 758.4c0-12.371 10.029-22.4 22.4-22.4h179.2c12.371 0 22.4 10.029 22.4 22.4v179.2c0 12.371-10.029 22.4-22.4 22.4h-179.2c-12.371 0-22.4-10.029-22.4-22.4v-179.2z",
        ],
        attrs: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 16,
        tags: ["cube"],
      },
      attrs: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
      properties: {
        order: 115,
        id: 0,
        name: "cube",
        prevSize: 16,
        code: 59670,
      },
      setIdx: 0,
      setId: 1,
      iconIdx: 23,
    },
  ],
  height: 1024,
  metadata: {
    name: "icomoon",
  },
  preferences: {
    showGlyphs: true,
    showCodes: true,
    showQuickUse: true,
    showQuickUse2: true,
    showSVGs: true,
    fontPref: {
      prefix: "icon-",
      metadata: {
        fontFamily: "icomoon",
        majorVersion: 1,
        minorVersion: 0,
      },
      metrics: {
        emSize: 1024,
        baseline: 6.25,
        whitespace: 50,
      },
      embed: false,
      cssVars: true,
      cssVarsFormat: "scss",
      showSelector: true,
      selector: "class",
      classSelector: ".icon",
    },
    imagePref: {
      prefix: "icon-",
      png: true,
      useClassSelector: true,
      color: 0,
      bgColor: 16777215,
    },
    historySize: 50,
    gridSize: 16,
    showGrid: true,
  },
};

export default icons;
