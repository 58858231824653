import React, { Component, useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Message } from "@foris/foris-ui";
import { groupBy } from "lodash";
import { IForecastContextInterface } from "../../../../../context";
import { UNITS_SUBSCRIPTION } from "../queries";
import LevelView from "./LevelView/LevelView";
import CrosslistView from "./CrossListView/CrossListView";
import SubjectView from "./SubjectListView/SubjectListView";
import { useQueryParam } from "../utils";

export class SubscriptionWrapper extends Component<any, any> {
  render() {
    return this.props.children;
  }
}

const UnitsResult = (props: IForecastContextInterface) => {
  const { dashboard, routes, client, filterSearchState } = props;
  const tab = useQueryParam()?.get("tab");
  const idTab = tab ? (parseInt(tab) <= 2 ? parseInt(tab) : 0) : 0;
  const [defaultIndexTab, setDefaultIndexTab] = useState<number>(idTab);
  const [initTab, setInitTab] = useState<boolean>(true);
  const parentContextHasData =
    (dashboard.unitView.crosslistView ||
      dashboard.unitView.levelView ||
      dashboard.unitView.subjectView) &&
    (dashboard.unitView.crosslistView.length ||
      dashboard.unitView.levelView.length ||
      dashboard.unitView.subjectView.length);

  const {
    levelView: levelItems,
    subjectView: subjectItems,
    crosslistView: crosslistItems,
  } = dashboard.unitView;

  const groupedLevels: any = groupBy(dashboard.unitView.levelView, function(level: any) {
    return `${level.curriculum.name}:${level.curriculum.program.id}`;
  });

  const selectIndexTab = () => {
    switch (true) {
      case Object.keys(groupedLevels).length > 0:
        setDefaultIndexTab(0);
        break;
      case subjectItems && subjectItems.length > 0:
        setDefaultIndexTab(1);
        break;
      case crosslistItems && crosslistItems.length > 0:
        setDefaultIndexTab(2);
        break;
      default:
        setDefaultIndexTab(-1);
        break;
    }
  };

  const displayUnits = () => {
    const groupedLevels: any = groupBy(dashboard.unitView.levelView, function(level: any) {
      return `${level.curriculum.name}:${level.curriculum.program.id}`;
    });
    const departmentSelected = dashboard.currentDepartmentListSelected;
    const programSelected = dashboard.currentProgramListSelected;
    const curriculumSelected = dashboard.currentCurriculumListSelected;
    const filterSelected = {
      department: departmentSelected?.value,
      program: programSelected?.value,
      curriculum: curriculumSelected?.value,
    };

    return (
      <Tabs selectedIndex={defaultIndexTab} onSelect={(index: number) => setDefaultIndexTab(index)}>
        <TabList>
          {/* Opción Currículos */}
          <Tab disabled={Object.keys(groupedLevels).length === 0}>
            Currículos ({Object.keys(groupedLevels).length})
          </Tab>
          {/* Opción Asignaturas */}
          <Tab disabled={subjectItems.length === 0}> Asignaturas ({subjectItems.length})</Tab>
          {/* Opción Listas cruzadas  */}
          <Tab disabled={crosslistItems.length === 0}>
            {" "}
            Listas cruzadas ({crosslistItems.length})
          </Tab>
        </TabList>
        {/* Sección opción Currículos */}
        <TabPanel>
          <LevelView routes={routes} items={levelItems} filterSelected={filterSelected} />
        </TabPanel>
        {/* Sección opción Asignaturas */}
        <TabPanel>
          <SubjectView items={subjectItems} routes={routes} filterSelected={filterSelected} />
        </TabPanel>
        {/* Sección opción Listas cruzadas  */}
        <TabPanel>
          <CrosslistView routes={routes} items={crosslistItems} filterSelected={filterSelected} />
        </TabPanel>
      </Tabs>
    );
  };

  const handleSuscribe = () => {
    client &&
      client
        .subscribe({
          query: UNITS_SUBSCRIPTION,
          variables: {
            projectId: props.routes.context.project,
          },
        })
        .subscribe({
          next: (response: any) => {
            if (props.dashboard.unitView) {
              const unitView: any = props.dashboard.unitView;
              switch (response.data.unitUpdated.kind) {
                case "LEVEL":
                  unitView["levelView"].forEach((item: any) => {
                    if (item.unit.id === response.data.unitUpdated.id) {
                      item.unit.progress = response.data.unitUpdated.progress;
                    }
                  });
                  break;
                case "SUBJECT":
                  unitView["subjectView"].forEach((item: any) => {
                    if (item.unit.id === response.data.unitUpdated.id) {
                      item.unit.progress = response.data.unitUpdated.progress;
                    }
                  });
                  break;
                case "CROSSLIST":
                  unitView["crosslistView"].forEach((item: any) => {
                    if (item.unit.id === response.data.unitUpdated.id) {
                      item.unit.progress = response.data.unitUpdated.progress;
                    }
                  });
                  break;
              }

              props.updateDashboardCurrentData("unitView", unitView);
            }
          },
          error(err: any) {
            console.error("err", err);
          },
        });
  };

  handleSuscribe();

  useEffect(() => {
    const { levelView, subjectView, crosslistView } = dashboard.unitView;
    const changeData = levelView || subjectView || crosslistView;

    if (initTab && changeData) {
      setInitTab(false);
    } else if (!initTab) {
      selectIndexTab();
    }
  }, [dashboard.unitView]);

  if (parentContextHasData) {
    //this.subscribeToUnits(this.props.projectId, subscribeToMore)
    return displayUnits();
  } else {
    return (
      <>
        {props.dashboard.filterSearchState === "TOUCHED" ? (
          <Message>
            <Message.Header>No se han encontrado resultados</Message.Header>
            <p>Por favor revise su selección de búsqueda.</p>
          </Message>
        ) : (
          <>{filterSearchState}</>
        )}
      </>
    );
  }
};

export default UnitsResult;
