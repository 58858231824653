import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "@foris/foris-ui";
import "@foris/foris-ui/lib/styles.css";
import logo from "../../../assets/svg/new_logo_darwined.svg";
import ContextScenario from "./contextScenario/ContextScenario";
import DropDownMenu from "./dropdownMenu/DropdownMenu";
import MenuHeader from "./menu/Menu";
import "../../../assets/scss/app.scss";
import "../../../grid__css_poc/bootstrap-grid.min.css";
import cx from "classnames";
import css from "./layout.module.scss";

const Layout: React.FC<any> = (props: any) => {
  return (
    <div className={"bootstrap-wrapper"}>
      <header className={cx(css.header, "container-row", "row--noWrap")}>
        {props.context && (
          <MenuHeader
            logoutUrl={props.context.auth.logoutUrl}
            context={props.context}
            routes={props.routes}
          />
        )}
        <Link to={props.routes && props.routes.dashboard()} className={css.cntImage}>
          <figure className={cx("container-row", "row--center", "row_align--center")}>
            <img src={logo} alt="Editor de Demandas" className={css.img} />
          </figure>
        </Link>
        <div className={css.cntInfo}>
          <p className={css.cntInfo_nameModule}>Editor de Demandas</p>
        </div>
        <ContextScenario />
        <div className={css.products}>
          <Menu.Menu position="left">
            <Menu.Item style={{ cursor: "pointer" }}>
              <DropDownMenu />
            </Menu.Item>
          </Menu.Menu>
        </div>
      </header>
      <div className={"container"} style={{ marginTop: "2em" }}>
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
