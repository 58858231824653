import React from "react";
import Select from "react-select";
import styled from "styled-components";

const ControlItem = styled.div`
  .with___error {
    & > div:first-child {
      border: 1px solid red;
    }
  }
`;

type IFieldSelectProps = {
  label: string;
  placeholder?: string;
  options: object[];
  defaultValue: any;
  value?: any;
  onChangeValue: Function;
  isDisabled?: boolean;
  className?: string;
};

const FieldSelect = (props: IFieldSelectProps) => {
  if (props && props.defaultValue) {
    if (!props.defaultValue || (props.defaultValue && !props.defaultValue.value)) {
      delete props.defaultValue;
      props.value = null;
    }
  }

  return (
    <div>
      <label className="label">{props.label}</label>
      <ControlItem className="control">
        <Select
          {...props}
          noOptionsMessage={() => "No se han encontrado resultados"}
          onChange={(value: any) => {
            props.onChangeValue(value);
          }}
        />
      </ControlItem>
    </div>
  );
};

export default FieldSelect;
