import React, { useState, useEffect } from "react";
import cx from "classnames";
import CrossListItem from "./CrossListItem";
import { ICrossList, IFilterSelected } from "../../models";
import Forecast from "../../../../../../../../routes/Forecast";
import Input from "./../../../../../../../../common/components/Input/Input";
import css from "./crossListView.module.scss";

interface ICrossListViewProps {
  items: Array<ICrossList>;
  routes: Forecast;
  filterSelected?: IFilterSelected;
}

const CrossListView: React.FC<ICrossListViewProps> = (props: ICrossListViewProps) => {
  const { items, routes, filterSelected } = props;
  const [source, setSource] = useState([]);

  const filterList = (e: any) => {
    if (e.target.value === "") setSource(items);
    const valueSearch = e.target.value.toLowerCase();
    const updatedList: any = [];
    items.forEach((item: ICrossList): any => {
      if (item.unit.label.toLowerCase().search(valueSearch) !== -1) {
        updatedList.push(item);
      }
    });
    setSource(updatedList);
  };

  useEffect(() => {
    setSource(items);
  }, [items]);

  return (
    <section className={cx(css.cntCrossList, "container-row")}>
      {items.length > 0 && (
        <div className={cx(css.cntCrossList_filter, "container-row")}>
          <Input
            placeholder="Filtrar..."
            onChange={(value: any) => filterList(value)}
            icon="search"
          />
        </div>
      )}
      <>
        {source && source.length ? (
          <section className={cx(css.cntTable, "container-row")}>
            <ul className={cx(css.cntHeaderList, "container-row")}>
              <li className={cx(css.cntHeaderList_item, css.cntHeaderList_item__progressBar)}>
                Progreso
              </li>
              <li className={cx(css.cntHeaderList_item)}>Código</li>
              <li className={cx(css.cntHeaderList_item)}>Nombre</li>
              <li className={cx(css.cntHeaderList_item)}>D total</li>
              <li className={cx(css.cntHeaderList_item)}>Aprobada</li>
            </ul>

            <div className="container-row">
              {source
                .filter((item: ICrossList) => item.unit)
                .map((item: ICrossList, indexCrossList: number) => (
                  <CrossListItem
                    key={indexCrossList}
                    unit={item}
                    routes={routes}
                    filterSelected={filterSelected}
                  />
                ))}
            </div>
          </section>
        ) : (
          <p className={cx(css.cntCrossList_textEmpty, "col_12")}>No hay resultados para mostrar</p>
        )}
      </>
    </section>
  );
};

export default CrossListView;
