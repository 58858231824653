import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import resolversDefaults from "./resolvers";
import { GRAPHQL_URI, GRAPHQL_WS_URI } from "./common/config";
import fakeAuth from "./authClient";

import { Socket as PhoenixSocket } from "phoenix";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { hasSubscription } from "@jumpn/utils-graphql";

const CURRENT_URL_PATH: any = new URL(window.location.href);
const USER_TOKEN_PARAM = CURRENT_URL_PATH.searchParams.get("token");
const TOKEN = USER_TOKEN_PARAM ? USER_TOKEN_PARAM : fakeAuth.getUserData().token;
const darwinLogin = process.env.REACT_APP_DARWINED_URL;

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    return object.id;
    // if (object.__typename != null && object.id != null) {
    //   return object.id
    // }
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
      if (extensions.code === "UNAUTHENTICATED") {
        window.location.href = darwinLogin;
      }
    });
  }

  if (networkError) {
    console.log("networkError >", networkError);
    console.log(`[Network error ${operation.operationName}]: ${networkError.message}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const context = {
    headers: {
      ...headers,
      Authorization: `Bearer ${TOKEN}`,
    },
  };
  return context;
});

const httpLink = new HttpLink({ uri: GRAPHQL_URI });
/*
const wsLink = new WebSocketLink({
  uri: `${GRAPHQL_WS_URI}`,
  options: {
    reconnect: true,
    connectionParams: () => {
      return {
        authToken: TOKEN,
      };
    },
  },
});
*/

// Create a standard Phoenix websocket connection. If you need
// to provide additional params, like an authentication token,
// you can configure them in the `params` option.
//
// If you plan to reconnect the socket with updated parameters,
// you must provide a function to the `params` option. If you
// provide the parameters directly as an object, the updated
// parameters will not be picked up when the socket reconnects.
const phoenixSocket = new PhoenixSocket(GRAPHQL_WS_URI, {
  params: () => {
    const token = TOKEN;
    if (token) {
      return { token: token };
    } else {
      return {};
    }
  },
});

// Wrap the Phoenix socket in an AbsintheSocket.
const absintheSocket = AbsintheSocket.create(phoenixSocket);

// Create an Apollo link from the AbsintheSocket instance.
const websocketLink = createAbsintheSocketLink(absintheSocket);

const link = split(operation => hasSubscription(operation.query), websocketLink, httpLink);

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([errorLink, authLink, link]),
  resolvers: resolversDefaults.resolvers,
});

cache.writeData({
  data: resolversDefaults.defaults,
});

export default client;
