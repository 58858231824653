import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoDocentes from "../../../../assets/svg/icono_docentes.svg";
import logoAsignaturas from "../../../../assets/svg/icono_asignaturas.svg";

import jenny from "../../../../assets/img/jenny.jpeg";
import jechu from "../../../../assets/img/jechu.jpeg";
import fran from "../../../../assets/img/fran.jpeg";

const MODULES = [
  {
    code: "instructors",
    name: "Docentes - 76%",
    logo: logoDocentes,
    path: "/wizard/instructors",
  },
  {
    code: "instructors_offer",
    name: "Asignaturas - 35%",
    logo: logoAsignaturas,
    path: "/wizard/instructors_offer",
  },
];

class Landing extends Component {
  render() {
    return (
      <main>
        <section className="main-section hero is-fullheight-with-navbar">
          <div className="hero-body">
            <div className="container is-fluid">
              <div className="columns">
                <div className="column is-three-quarters">
                  <div className="is-content has-background-white">
                    <h1 className="title has-text-grey">Bienvenido, Felipe</h1>
                    <div className="notification is-info">
                      <button className="delete" />
                      Quedan <span>5 días</span> para que se cierre el período de revisión y tienes{" "}
                      <span>21 ajustes pendientes</span>.
                    </div>
                    <div className="section">
                      <div className="content">
                        <ul className="module-list is-unstyled">
                          {MODULES.map(module => (
                            <li key={module.code} className="has-text-centered">
                              <Link to={module.path}>
                                <figure className="image">
                                  <img
                                    src={module.logo}
                                    alt="forecast module"
                                    style={{
                                      width: "96px",
                                      height: "96px",
                                    }}
                                  />
                                </figure>
                                <p className="module-name">{module.name}</p>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="is-content has-background-white">
                    <h1 className="title has-text-grey">Actividad</h1>
                    <h2 className="subtitle has-text-grey">18 de Octubre de 2018</h2>

                    <div className="activity-list">
                      <div className="activity-item">
                        <div className="activity-avatar">
                          <figure className="image is-48x48">
                            <img className="is-rounded" src={jenny} alt="jenny avatar" />
                          </figure>
                        </div>
                        <div className="activity-content">
                          <div className="activity-header">
                            <div className="activity-username">Jonathan Urzúa</div>
                            <div className="activity-date">10:24 AM</div>
                          </div>
                          <div className="activity-body">
                            modificó al docente <Link to="/wizard/instructor/1">Germán Casas</Link>
                          </div>
                        </div>
                      </div>
                      <div className="activity-item">
                        <div className="activity-avatar">
                          <figure className="image is-48x48">
                            <img className="is-rounded" src={fran} alt="fran avatar" />
                          </figure>
                        </div>
                        <div className="activity-content">
                          <div className="activity-header">
                            <div className="activity-username">Franziska Körner</div>
                            <div className="activity-date">09:46 AM</div>
                          </div>
                          <div className="activity-body">
                            modificó al docente <Link to="/wizard/instructor/1">Camila Gómez</Link>
                          </div>
                        </div>
                      </div>

                      <div className="activity-item">
                        <div className="activity-avatar">
                          <figure className="image is-48x48">
                            <img className="is-rounded" src={jechu} alt="jechu avatar" />
                          </figure>
                        </div>
                        <div className="activity-content">
                          <div className="activity-header">
                            <div className="activity-username">Mariajesús Urgarte</div>
                            <div className="activity-date">09:34 AM</div>
                          </div>
                          <div className="activity-body">
                            modificó al docente{" "}
                            <Link to="/wizard/instructor/1">Eliana Narvaez</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Landing;
