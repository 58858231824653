import React, { useState, useEffect } from "react";
import cx from "classnames";
import Modal from "../../modal/Modal";
import ScenariosSelect from "./contextSelect/ScenariosSelect";
import ProjectsSelect from "./contextSelect/ProjectsSelect";
import WorkspaceSelect from "./contextSelect/WorkspaceSelect";
import css from "./context-modal.module.scss";

interface ISelectValue {
  value: string;
  label: string;
}

interface ISelectOptions {
  value: ISelectValue;
  options?: any;
}

interface IContextModalProps {
  open: boolean;
  onClose: Function;
  workspace: ISelectOptions;
  scenario: ISelectOptions;
  project: ISelectOptions;
  institutionId: string;
  onSave: any;
}

const defaultValue = { label: "", value: "" };

const ContextModal: React.FC<IContextModalProps> = (props: IContextModalProps) => {
  const { institutionId } = props;
  const [open, setChange] = useState(props.open);
  const [workspaceValue, setWorkspace] = useState(props.workspace?.value);
  const [scenarioValue, setScenario] = useState(props.scenario?.value);
  const [projectValue, setProject] = useState(props.project?.value);

  useEffect(() => {
    if (open !== props.open) setChange(props.open);
  }, [open, props.open]);

  const validateData = (): void => {
    const { workspace, scenario } = props;
    const newWorkspace = workspaceValue ? workspaceValue.value : null;
    if (workspace?.value?.value !== newWorkspace) setWorkspace(workspace.value);
    const newScenario = scenarioValue ? scenarioValue.value : null;
    if (scenario?.value?.value !== newScenario) setScenario(scenario.value);
  };

  let activeSave = false;
  if (workspaceValue && scenarioValue && projectValue) activeSave = true;

  return (
    <Modal
      title="CAMBIO DE CONTEXTO"
      show={open}
      onClose={(): void => {
        if (activeSave) {
          validateData();
          props.onClose();
        }
      }}
      headerColor="#294870"
    >
      <div className={css.context_content}>
        <div className={css.context_select}>
          <div className={css.input_select}>
            <label>AMBIENTE</label>
            <WorkspaceSelect
              workspaceValue={workspaceValue}
              onChange={(value: any): void => {
                setWorkspace(value);
                setScenario(defaultValue);
                setProject(defaultValue);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>ESCENARIO</label>
            <ScenariosSelect
              workspaceValue={workspaceValue}
              scenarioValue={scenarioValue}
              onChange={(value: any): void => {
                setScenario(value);
                setProject(defaultValue);
              }}
            />
          </div>
          <div className={css.input_select}>
            <label>PROYECTO</label>
            <ProjectsSelect
              institutionId={institutionId}
              scenarioValue={scenarioValue}
              workspaceValue={workspaceValue}
              projectValue={projectValue}
              onChange={(value: any) => setProject(value)}
            />
          </div>
        </div>
        <footer>
          <button
            className={cx(css.btn_footer_primary, !activeSave && css.disabled)}
            disabled={!activeSave}
            onClick={(): void => {
              props.onClose();
              props.onSave({
                context: {
                  workspace: workspaceValue,
                  scenario: scenarioValue,
                  project: projectValue,
                },
              });
            }}
          >
            Guardar
          </button>
        </footer>
      </div>
    </Modal>
  );
};

export default ContextModal;
