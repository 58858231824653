import React from "react";
import { Accordion } from "react-accessible-accordion";
import cx from "classnames";
import { groupBy } from "lodash";
import LevelItem from "./LevelItem";
import { ILevel, IFilterSelected } from "../../models";
import Forecast from "../../../../../../../../routes/Forecast";
import AccordionComponent from "../../../../../../../../common/components/Accordion/Accordion";
import css from "./../LevelView/levelView.module.scss";

interface ILevelViewProps {
  items: Array<ILevel>;
  routes: Forecast;
  filterSelected?: IFilterSelected;
}
const LevelView: React.FC<ILevelViewProps> = (props: ILevelViewProps) => {
  const { items, filterSelected } = props;
  const levels = items && items.length > 0 ? [...items] : null;
  const groupedLevels: any = groupBy(levels, (level: ILevel) => {
    return [level.curriculum.program.name, level.curriculum.name];
  });

  return (
    <>
      {groupedLevels && Object.keys(groupedLevels).length ? (
        <Accordion allowMultipleExpanded>
          {Object.keys(groupedLevels).map((level: string, groupIndex: number) => {
            const total = groupedLevels[level].length;
            const filterZero = groupedLevels[level].filter(
              (item: ILevel) => item.unit.progress === 0,
            ).length;
            const filterFull = groupedLevels[level].filter(
              (item: ILevel) => item.unit.progress === 1,
            ).length;

            return (
              <AccordionComponent
                key={`accordionLevel-${groupIndex}`}
                id={groupIndex}
                className={cx(css.cntItemsLevel, "container-row")}
                header={
                  <div className={cx("container-row", "row_align--center")}>
                    <div
                      className={cx(
                        css.cntItemsLevel_status,
                        filterZero === total && css.cntItemsLevel_status__empty,
                        filterFull === total && css.cntItemsLevel_status__full,
                      )}
                    ></div>
                    <h3 className={cx(css.cntItemsLevel_title, "col_10")}>
                      {`${groupedLevels[level][0].curriculum.program.name}`}{" "}
                      <span
                        className={css.detail}
                      >{`| ${groupedLevels[level][0].curriculum.name}`}</span>
                    </h3>
                  </div>
                }
              >
                <div className={cx(css.cntItemsProgressbar, "container-row")}>
                  <h4 className={cx(css.cntItemsProgressbar_title, "col_12")}>Niveles</h4>
                  {groupedLevels[level].map((item: ILevel) => {
                    if (item.unit) {
                      return (
                        <LevelItem
                          routes={props.routes}
                          level={item}
                          className={cx(css.cntItemsProgressbar_item)}
                          filterSelected={filterSelected}
                        />
                      );
                    } else {
                      return <p className={css.textEmpty}>No hay niveles para esta carrera</p>;
                    }
                  })}
                </div>
              </AccordionComponent>
            );
          })}
        </Accordion>
      ) : (
        <p className={css.textEmpty}>No hay resultados para mostrar</p>
      )}
    </>
  );
};

export default LevelView;
