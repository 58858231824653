export const updateImplementedData = (
  func: Function,
  rowIndex: number,
  columnId: number,
  value: any,
) => {
  func((old: any) =>
    old.map((row: any, index: number) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    }),
  );
};

export const updateImplementedAllData = (func: Function, columnId: number, value: any) => {
  func((old: any) =>
    old.map((row: any) => {
      return {
        ...row,
        [columnId]: value,
      };
    }),
  );
};
