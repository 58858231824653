import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Icon, Loader, Form, Button, Modal, Divider, Table } from "@foris/foris-ui";
import { SearchField } from "./index";
import { Query, useApolloClient } from "react-apollo";
import { GET_DEPARTMENTS, GET_UNITS } from "../../dashboard/queries";
import { EDIT_CROSSLIST_MUTATION } from "../queries";
import Forecast from "../../../../../../../routes/Forecast"; // alias please :c
import { GET_UNIT_DETAIL } from "../../editor/queries";

import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
};

const TableWrapper = styled.div`
  .ui.table > tbody > tr > td,
  .ui.table > tr > td {
    padding: 0.8em 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:last-child {
    padding-right: 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:first-child {
    padding-left: 0.9em;
  }
`;

const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    id: d.id,
    text: d.name,
  }));
};

const FormCLEdit = (props: any) => {
  const { projectId, unitId } = props;
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [demands, setDemands]: any = useState({});
  const [department, setDepartment] = useState({ value: null, label: null });
  const [isLoading, setIsLoading] = useState(false);
  const [codeHasEmptyError, setCodeHasEmptyError] = useState(false);
  const [departmentHasEmptyError, setDepartmentHasEmptyError] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [originalCodeName, setOriginalCodeName] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showOnDeleteConfirmation, setShowOnDeleteConfirmation] = useState(false);
  const [countDemandsWithCrossList, setCountDemandsWithCrossList] = useState(0);
  const [codeHasIncorrectValue, setCodeHasIncorrectValue] = useState(false);
  const routes: Forecast = props.routes;

  const client = useApolloClient();

  const handlecodeChange = (e: React.SyntheticEvent, { value }: any) => {
    const inputValue = value.toUpperCase();
    if (/^[A-Z\d._-]*$/.test(inputValue)) {
      setCodeHasIncorrectValue(false);
      setCodeHasEmptyError(false);
    }
    setCode(inputValue);
  };

  const handleNameChange = ({ value }: any) => setName(value);

  const onSelectDemand = (demand: any) => {
    setDemands({ ...demands, [demand.id]: demand });
  };

  const demandsFactory = (demands: any) => {
    const array: any[] = [];

    Object.keys(demands).forEach((demandKey: any) => {
      array.push({
        id: demandKey,
        value: demands[demandKey].value,
        checked: demands[demandKey].checked,
        adjusted: demands[demandKey].adjusted,
      });
    });

    return array;
  };

  const updateTotalValue = (): number => {
    let total = 0;
    Object.entries(demands).forEach((demand: any) => (total += demand[1].adjusted));
    return total;
  };

  const sendForm = () => {
    setIsLoading(true);
    const demandsF = demandsFactory(demands);

    const crossListEditObject = {
      id: props.unitId,
      input: {
        kind: "crosslist",
        label: code,
        projectId: routes.context.project,
        departmentId: department.value,
        description: name,
        demands: demandsF,
      },
    };

    client
      .mutate({
        mutation: EDIT_CROSSLIST_MUTATION,
        variables: crossListEditObject,

        update: (proxy: any, response: any) => {
          // if departmentId is null === || !currentProgramList --> 'not have cache for this query'
          if (
            props.dashboard.currentDepartmentListSelected.value ||
            props.dashboard.currentProgramListSelected.value ||
            props.dashboard.currentCurriculumListSelected.value
          ) {
            const departmentsToUpdate: any = [];

            props.dashboard.currentProgramList.forEach((item: any) => {
              departmentsToUpdate.push(item.id === "*" ? null : item.id);
            });

            // update all program in current department
            departmentsToUpdate.forEach((programId: any) => {
              try {
                const data = proxy.readQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: routes.context.project,
                    filter: {
                      departmentId: props.dashboard.currentDepartmentListSelected.value,
                      programId: programId,
                      curriculumId:
                        props.dashboard.currentCurriculumListSelected.value === "*"
                          ? null
                          : props.dashboard.currentCurriculumListSelected.value,
                    },
                  },
                });

                if (data) {
                  const newCrossListView = data.project.dashboard.crosslistView.filter(
                    (item: any) => item.unit.id !== response.data.editUnit.unit.id,
                  );

                  data.project.dashboard.crosslistView = newCrossListView;
                  proxy.writeQuery({
                    query: GET_UNITS,
                    variables: {
                      projectId: routes.context.project,
                      filter: {
                        departmentId: props.currentDepartment,
                        programId: programId,
                      },
                    },
                    data: data,
                  });
                }
              } catch (e) {
                console.log(e);
              }
            });
          }
        },
      })
      .then((response: any) => {
        const status = response.data.editUnit.status;
        if (status.code) {
          switch (status.code) {
            case 200:
              if (status.msg === "SUCCESS_DELETED") {
                toast.info("La lista se ha eliminado correctamente", TOAST_OPTIONS);
                setTimeout(() => {
                  props.history.push(routes.dashboard());
                }, 900);
              } else {
                toast.info("La lista se ha actualizado correctamente", TOAST_OPTIONS);
                props.history.push(routes.unit(props.unitId));
              }
              break;
            case 409:
              if (status.msg === "CROSSLIST_LABEL_ALREADY_EXITS") {
                toast.warn("Ya existe una lista con este código", TOAST_OPTIONS);
              } else if (status.msg === "EMPTY_DEMAND") {
                toast.warn("No puedes crear una lista vacía", TOAST_OPTIONS);
              } else {
                toast.warn("Ocurrió un error, inténtalo nuevamente.", TOAST_OPTIONS);
              }
              break;
          }
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (code === "") {
      setCodeHasEmptyError(true);
      return;
    }

    if (!department.hasOwnProperty("value")) {
      setDepartmentHasEmptyError(true);
      return;
    }

    let demandsWithCrossList = 0;
    Object.keys(demands).forEach((key: any) => {
      if (
        demands[key]["relatedUnits"] &&
        demands[key]["relatedUnits"]["crosslist"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"] !== originalCodeName
      ) {
        demandsWithCrossList++;
      }
    });
    if (demandsWithCrossList > 0) {
      setCountDemandsWithCrossList(demandsWithCrossList);
      setShowConfirmation(true);
    } else if (!Object.keys(demands).length) {
      setShowOnDeleteConfirmation(true);
    } else {
      sendForm();
    }
  };

  const getUnitDetail = async () => {
    try {
      const {
        data: { project },
      } = await client.query({
        query: GET_UNIT_DETAIL,
        variables: { projectId: projectId, unitId: unitId },
      });

      setCode(project.unit.label || "");
      setOriginalCodeName(project.unit.label);
      setName(project.unit.description || "");
      setDepartment({
        value: project.unit.department.id,
        label: project.unit.department.name,
      });

      //SET DEMAND OBJECT
      const newDemands = {};
      project.unit.demands.forEach((demand: any) => {
        newDemands[demand.id] = demand;
      });
      setDemands({ ...newDemands });
    } catch (error) {
      console.log(error);
    }

    setInitialLoading(false);
  };

  useEffect(() => {
    getUnitDetail();
  }, []);

  return (
    <Fragment>
      {initialLoading && (
        <>
          <br />
          <br />
          <Loader active />
        </>
      )}
      {!initialLoading && (
        <>
          <div style={{ marginBottom: "2.5em", backgroundColor: "white", padding: "2em 2.5em" }}>
            <h3>Editar lista cruzada</h3>
            <br />
            <Form>
              <div className="row">
                <div className="col">
                  <Form.Group>
                    <Form.Field width={2}>
                      <Form.Input
                        name="code"
                        label="Código"
                        defaultValue={code}
                        onChange={handlecodeChange}
                        placeholder="Código"
                        error={codeHasEmptyError || codeHasIncorrectValue}
                      />
                      {codeHasIncorrectValue && (
                        <div style={{ lineHeight: "14px", marginTop: "3px" }}>
                          <small
                            style={{ color: "#ff3860", fontWeight: "bold", fontSize: "13.5px" }}
                          >
                            El código debe contener sólo caracteres alfanuméricos, puntos y guiones
                          </small>
                        </div>
                      )}
                    </Form.Field>

                    <Form.Field>
                      <Form.Input
                        label="Nombre"
                        name="name"
                        defaultValue={name}
                        placeholder="Nombre"
                        onChange={handleNameChange}
                      />
                    </Form.Field>

                    <Form.Field width={5}>
                      <Query
                        query={GET_DEPARTMENTS}
                        variables={{ projectId: routes.context.project }}
                      >
                        {({ loading, error, data }: any) => {
                          if (loading) return <p>Loading...</p>;
                          if (error) return <p>Error :(</p>;
                          return (
                            <Form.Select
                              search
                              label="Escuela"
                              className="ellipsisText"
                              options={filterFactory(data.project.departments)}
                              defaultValue={department.value}
                              error={departmentHasEmptyError}
                              placeholder="Selecciona una escuela"
                              onChange={(e: React.SyntheticEvent, filterValue: any) => {
                                setDepartment(filterValue);
                                setDepartmentHasEmptyError(false);
                              }}
                            />
                          );
                        }}
                      </Query>
                    </Form.Field>
                  </Form.Group>
                </div>
              </div>

              <Divider section />
              <div className="row">
                <div className="col">
                  <Form.Field width={5}>
                    <label className="label">Asignaturas</label>
                    <SearchField
                      projectId={routes.context.project}
                      onSelectDemand={onSelectDemand}
                      excludeFromResults={demands}
                    />
                  </Form.Field>
                </div>
              </div>
            </Form>

            <div className="row" style={{ marginTop: "2.5em" }}>
              <div className="col">
                <TableWrapper>
                  <Table style={{ width: "100%" }} basic="very" celled collapsing>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Carrera</Table.HeaderCell>
                        <Table.HeaderCell>Currículo</Table.HeaderCell>
                        <Table.HeaderCell>Código</Table.HeaderCell>
                        <Table.HeaderCell>Alias</Table.HeaderCell>
                        <Table.HeaderCell>Asignatura</Table.HeaderCell>
                        <Table.HeaderCell>Lista</Table.HeaderCell>
                        <Table.HeaderCell>Demanda</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    {Object.keys(demands).length ? (
                      <Table.Body>
                        {Object.keys(demands).map((key: string) => {
                          if (demands[key]) {
                            return (
                              <Table.Row key={demands[key].id}>
                                <Table.Cell>
                                  {demands[key].course.curriculum.program.name}
                                </Table.Cell>
                                <Table.Cell>{demands[key].course.curriculum.name}</Table.Cell>
                                <Table.Cell>{demands[key].course.code}</Table.Cell>
                                <Table.Cell>{demands[key].course.label}</Table.Cell>
                                <Table.Cell>{demands[key].course.code}</Table.Cell>
                                <Table.Cell style={{ textAlign: "center" }}>
                                  {demands[key].relatedUnits &&
                                  demands[key].relatedUnits.crosslist ? (
                                    <Link
                                      to={routes.unit(demands[key].relatedUnits.crosslist.id)}
                                      target="_blank"
                                    >
                                      {demands[key].relatedUnits.crosslist.code}
                                    </Link>
                                  ) : (
                                    <></>
                                  )}
                                </Table.Cell>
                                <Table.Cell style={{ textAlign: "center" }}>
                                  {demands[key].adjusted}
                                </Table.Cell>
                                <Table.Cell>
                                  <div className="buttons">
                                    <a
                                      className="button"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        const newDemands = { ...demands };
                                        delete newDemands[key];
                                        setDemands(newDemands);
                                      }}
                                    >
                                      <Icon name="trash alternate" />
                                    </a>
                                  </div>
                                </Table.Cell>
                              </Table.Row>
                            );
                          } else {
                            return <></>;
                          }
                        })}
                        <Table.Row style={{ backgroundColor: "#f1efef" }}>
                          <Table.Cell colSpan={6}>TOTAL</Table.Cell>
                          <Table.Cell style={{ textAlign: "center" }}>
                            {updateTotalValue()}
                          </Table.Cell>
                          <Table.Cell />
                        </Table.Row>
                      </Table.Body>
                    ) : (
                      <Table.Body />
                    )}
                  </Table>
                </TableWrapper>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div style={{ padding: "2em 0 1em 0", textAlign: "right" }}>
                  <Link
                    to={routes.unit(props.unitId)}
                    style={{ display: "inline-block", marginRight: "1em" }}
                  >
                    <Button>Cancelar</Button>
                  </Link>
                  <Button
                    primary
                    loading={isLoading}
                    onClick={(e: React.SyntheticEvent) => handleSubmit(e)}
                  >
                    Guardar cambios
                  </Button>
                </div>
              </div>
            </div>

            <Modal size="small" open={showConfirmation}>
              <Modal.Header>Confirmación</Modal.Header>
              <Modal.Content>
                <p>
                  {countDemandsWithCrossList > 1
                    ? `Con esta acción se cambiarán ${countDemandsWithCrossList} asignaturas desde otras listas cruzadas a la presente.`
                    : `Con esta acción se cambiará ${countDemandsWithCrossList} asignatura desde otra lista cruzada a la presente.`}
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setShowConfirmation(false)}>Cancelar</Button>
                <Button
                  primary
                  content="Continuar"
                  onClick={() => {
                    setShowConfirmation(false);
                    sendForm();
                  }}
                />
              </Modal.Actions>
            </Modal>

            {/*   DELETE   */}
            <Modal size="small" open={showOnDeleteConfirmation}>
              <Modal.Header>Lista cruzada inválida</Modal.Header>
              <Modal.Content>
                <p>No se pueden guardar listas cruzadas vacías</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  primary
                  onClick={() => {
                    setShowOnDeleteConfirmation(false);
                  }}
                >
                  Aceptar
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default withRouter(FormCLEdit);
