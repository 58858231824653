import React from "react";
import { withForecastContext, IForecastContextInterface } from "../../../../context";
import { BreadCrumb } from "../../../../../../common";
import { FormCLAdd } from "./components";

const Container = (props: any) => {
  const breadCrumbItems = [
    { content: "Editor de demanda", link: true, to: props.routes ? props.routes.dashboard() : "/" },
    { content: "Crear lista cruzada", link: false },
  ];

  return (
    <div className="row">
      <div className="col">
        <BreadCrumb items={breadCrumbItems} />
        {/* <h3 style={{ margin: "0px 0px 1.5em 0px", padding: "0px" }}>
          Test-41 <span style={{ fontWeight: 400 }}>(BASE / UPCH / Origen: 525)</span>
        </h3> */}
        {props.content}
      </div>
    </div>
  );
};

const AddPageContainer = ({ forecastContext }: { forecastContext?: IForecastContextInterface }) =>
  forecastContext && (
    <Container
      routes={forecastContext.routes}
      content={
        <FormCLAdd
          routes={forecastContext.routes}
          {...forecastContext}
          projectId={forecastContext.projectId}
        />
      }
    />
  );

export default withForecastContext(AddPageContainer);
