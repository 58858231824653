import React, { useState, useEffect } from "react";
import cx from "classnames";
import SubjectListItem from "./SubjectListItem";
import { ISubject, IFilterSelected } from "../../models";
import Forecast from "../../../../../../../../routes/Forecast";
import Input from "./../../../../../../../../common/components/Input/Input";
import css from "./subjectListView.module.scss";

interface ISubjectListViewProps {
  items: Array<ISubject>;
  routes?: Forecast;
  filterSelected?: IFilterSelected;
}

const SubjectListView: React.FC<ISubjectListViewProps> = (props: ISubjectListViewProps) => {
  const { items, routes, filterSelected } = props;
  const [source, setSource] = useState([]);

  const filterList = (value: string | number) => {
    if (value === "") setSource(items);
    const valueSearch = value
      .toString()
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    const updatedList: any = [];

    items.forEach((item: ISubject): any => {
      const label = item.unit.label
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const subject = item.subject
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      if (label.search(valueSearch) !== -1 || subject.search(valueSearch) !== -1) {
        updatedList.push(item);
      }
    });
    setSource(updatedList);
  };

  useEffect(() => {
    setSource(items);
  }, [items]);

  return (
    <section className={cx(css.cntSubject, "container-row")}>
      {items.length > 0 && (
        <div className={cx(css.cntSubject_filter, "container-row")}>
          <Input
            placeholder="Filtrar..."
            onChange={e => filterList(e.target.value)}
            icon="search"
          />
        </div>
      )}
      <>
        {source && source.length ? (
          <section className={cx(css.cntTable, "container-row")}>
            <ul className={cx(css.cntHeaderList, "container-row")}>
              <li className={cx(css.cntHeaderList_item, css.cntHeaderList_item__progressBar)}>
                Progreso
              </li>
              <li className={cx(css.cntHeaderList_item, "col_3")}>Alias</li>
              <li className={cx(css.cntHeaderList_item, "col_4")}>Asignatura</li>
            </ul>

            <div className="container-row">
              {source
                .filter((item: ISubject) => item.unit)
                .map((item: ISubject, indexSubjectList: number) => (
                  <SubjectListItem
                    key={indexSubjectList}
                    unit={item}
                    routes={routes}
                    filterSelected={filterSelected}
                  />
                ))}
            </div>
          </section>
        ) : (
          <p className={cx(css.cntSubject_textEmpty, "col_12")}>No hay resultados para mostrar</p>
        )}
      </>
    </section>
  );
};

export default SubjectListView;
