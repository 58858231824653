import * as React from "react";
import css from "./checkbox.module.scss";
import cx from "classnames";
interface ICheckboxProps {
  className?: string;
  label?: string;
  disabled?: boolean;
  check?: boolean;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  ref?: any;
}

// class Checkbox extends React.Component<ICheckbox, any> {
//   render() {
//     const { className, label, disabled, check, onClick, onChange } = this.props;

//     return (
//       <section
//         className={`component_check ${className} ${disabled && "component_check--disabled"}`}
//       >
//         <label className="cnt_option">
//           <span className="cnt_option-label">{label}</span>
//           <input
//             className="cnt_option-input"
//             type="checkbox"
//             checked={check}
//             disabled={disabled}
//             onClick={(e: React.MouseEvent<HTMLInputElement>) =>
//               onClick && onClick(e.currentTarget.checked)
//             }
//             onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
//               onChange && onChange(e.currentTarget.checked)
//             }
//           />
//           <span className="cnt_option-check"></span>
//         </label>
//       </section>
//     );
//   }
// }

const Checkbox: React.FC<ICheckboxProps> = (props: ICheckboxProps) => {
  const { className, label, disabled, onClick, check, onChange, ref } = props;
  return (
    <div
      className={cx(
        css.component_check,
        className && className,
        disabled && css.component_check__disabled,
      )}
    >
      <label className={css.cnt_option}>
        <input
          className={cx(css.cnt_option_input, className && className)}
          checked={check}
          type="checkbox"
          disabled={disabled}
          onClick={(e: React.MouseEvent<HTMLInputElement>) =>
            onClick && onClick(e.currentTarget.checked)
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange && onChange(e.currentTarget.checked)
          }
          ref={ref}
        />
        <span className={css.cnt_option_check}></span>
        <span className={css.cnt_option_label}>{label}</span>
      </label>
    </div>
  );
};

export default Checkbox;
