export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Auth = {
  __typename?: "Auth";
  user?: Maybe<User>;
  status: Status;
  project?: Maybe<Project>;
};

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type Campus = {
  __typename?: "Campus";
  id: Scalars["ID"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type Course = {
  __typename?: "Course";
  id: Scalars["ID"];
  code?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  curriculum: Curriculum;
  level?: Maybe<Scalars["Int"]>;
  label?: Maybe<Scalars["String"]>;
};

export type CrosslistViewResult = {
  __typename?: "CrosslistViewResult";
  crosslist?: Maybe<Scalars["String"]>;
  unit?: Maybe<Unit>;
};

export type Curriculum = {
  __typename?: "Curriculum";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  program?: Maybe<Program>;
};

export type CurriculumFilter = {
  campusId?: Maybe<Scalars["ID"]>;
  departmentId?: Maybe<Scalars["ID"]>;
  shiftId?: Maybe<Scalars["ID"]>;
  modalityId?: Maybe<Scalars["ID"]>;
  programId?: Maybe<Scalars["ID"]>;
};

export type Dashboard = {
  __typename?: "Dashboard";
  levelView?: Maybe<Array<LevelViewResult>>;
  subjectView?: Maybe<Array<SubjectViewResult>>;
  crosslistView?: Maybe<Array<CrosslistViewResult>>;
};

export type DashboardLevelViewArgs = {
  filter?: Maybe<ViewFilter>;
};

export type DashboardSubjectViewArgs = {
  filter?: Maybe<ViewFilter>;
};

export type DashboardCrosslistViewArgs = {
  filter?: Maybe<ViewFilter>;
};

export type Demand = {
  __typename?: "Demand";
  id: Scalars["ID"];
  course: Course;
  user?: Maybe<User>;
  value?: Maybe<Scalars["Int"]>;
  adjusted?: Maybe<Scalars["Int"]>;
  checked?: Maybe<Scalars["Boolean"]>;
  equivalenceGroup?: Maybe<Scalars["String"]>;
  crosslistGroup?: Maybe<Array<Maybe<Demand>>>;
  relatedUnits?: Maybe<Scalars["JSON"]>;
  own?: Maybe<Scalars["Boolean"]>;
  responsible?: Maybe<User>;
  courseCode?: Maybe<Scalars["String"]>;
  courseLabel?: Maybe<Scalars["String"]>;
  programName?: Maybe<Scalars["String"]>;
  curriculumName?: Maybe<Scalars["String"]>;
};

export type DemandAdjustment = {
  demandId: Scalars["ID"];
  adjusted?: Maybe<Scalars["Int"]>;
  confirmed: Scalars["Boolean"];
  state?: Maybe<ValueState>;
};

export type DemandUnitInput = {
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Int"]>;
  checked?: Maybe<Scalars["Boolean"]>;
  adjusted?: Maybe<Scalars["Int"]>;
};

export type Department = {
  __typename?: "Department";
  id: Scalars["ID"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type Institution = {
  __typename?: "Institution";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
};

export type LevelViewResult = {
  __typename?: "LevelViewResult";
  curriculum?: Maybe<Curriculum>;
  level?: Maybe<Scalars["Int"]>;
  unit?: Maybe<Unit>;
};

export type Modality = {
  __typename?: "Modality";
  id: Scalars["ID"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  editDemand: Unit;
  addUnit: UnitResponse;
  editUnit: UnitResponse;
  deleteUnit: Scalars["Boolean"];
  addProject: ProjectResponse;
  syncOriginData: ProjectResponse;
  changeProjectStatus: Project;
  editProject: Project;
  exportDemands: ProjectResponse;
  report: Report;
  /** dev mutation */
  syncProjectData: ProjectResponse;
};

export type MutationEditDemandArgs = {
  unitId: Scalars["ID"];
  demandAdjustments?: Maybe<Array<Maybe<DemandAdjustment>>>;
};

export type MutationAddUnitArgs = {
  unitInput?: Maybe<UnitInput>;
};

export type MutationEditUnitArgs = {
  unitId: Scalars["ID"];
  unitInput?: Maybe<UnitInput>;
};

export type MutationDeleteUnitArgs = {
  unitId: Scalars["ID"];
};

export type MutationAddProjectArgs = {
  projectInput: ProjectInput;
};

export type MutationSyncOriginDataArgs = {
  projectId: Scalars["ID"];
};

export type MutationChangeProjectStatusArgs = {
  projectId: Scalars["ID"];
  status?: Maybe<Scalars["Boolean"]>;
};

export type MutationEditProjectArgs = {
  projectId: Scalars["ID"];
  projectInput: ProjectInput;
};

export type MutationExportDemandsArgs = {
  projectId: Scalars["ID"];
};

export type MutationReportArgs = {
  projectId: Scalars["ID"];
  type: ReportType;
};

export type MutationSyncProjectDataArgs = {
  projectId: Scalars["ID"];
};

export type Origin = {
  __typename?: "Origin";
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  demandCount?: Maybe<Scalars["Int"]>;
};

export type Program = {
  __typename?: "Program";
  id: Scalars["ID"];
  name: Scalars["String"];
  code: Scalars["String"];
  campus: Campus;
  shift: Shift;
  modality: Modality;
  department: Department;
  departmentId?: Maybe<Scalars["ID"]>;
};

export type ProgramFilter = {
  campusId?: Maybe<Scalars["ID"]>;
  departmentId?: Maybe<Scalars["ID"]>;
  shiftId?: Maybe<Scalars["ID"]>;
  modalityId?: Maybe<Scalars["ID"]>;
};

export type Project = {
  __typename?: "Project";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  access?: Maybe<ProjectAccess>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  institutionId?: Maybe<Scalars["String"]>;
  state?: Maybe<ProjectState>;
  logoutUrl?: Maybe<Scalars["String"]>;
  dashboard?: Maybe<Dashboard>;
  unit?: Maybe<Unit>;
  levelUnit?: Maybe<Unit>;
  findUnits?: Maybe<Array<Maybe<Unit>>>;
  campuses?: Maybe<Array<Maybe<Campus>>>;
  shifts?: Maybe<Array<Maybe<Shift>>>;
  modalities?: Maybe<Array<Maybe<Modality>>>;
  departments?: Maybe<Array<Maybe<Department>>>;
  programs?: Maybe<Array<Maybe<Program>>>;
  curriculums?: Maybe<Array<Maybe<Curriculum>>>;
  searchByCourse?: Maybe<Array<Maybe<Unit>>>;
  unitsByKind?: Maybe<Array<Maybe<Unit>>>;
  demands?: Maybe<Array<Maybe<Demand>>>;
  searchByDemand?: Maybe<Array<Maybe<Demand>>>;
  unisabanaDepartments?: Maybe<Array<Maybe<UnisabanaDepartment>>>;
};

export type ProjectUnitArgs = {
  unitId: Scalars["ID"];
};

export type ProjectLevelUnitArgs = {
  programId: Scalars["ID"];
  curriculumId: Scalars["ID"];
  level: Scalars["Int"];
};

export type ProjectFindUnitsArgs = {
  filter?: Maybe<ViewFilter>;
};

export type ProjectProgramsArgs = {
  filter?: Maybe<ProgramFilter>;
};

export type ProjectCurriculumsArgs = {
  filter?: Maybe<CurriculumFilter>;
};

export type ProjectSearchByCourseArgs = {
  q?: Maybe<Scalars["String"]>;
};

export type ProjectUnitsByKindArgs = {
  kind: UnitKind;
};

export type ProjectSearchByDemandArgs = {
  q?: Maybe<Scalars["String"]>;
};

export type ProjectAccess = {
  __typename?: "ProjectAccess";
  workspaceId?: Maybe<Scalars["ID"]>;
  workspaceName?: Maybe<Scalars["String"]>;
  scenarioId?: Maybe<Scalars["ID"]>;
  scenarioName?: Maybe<Scalars["String"]>;
  originId?: Maybe<Scalars["ID"]>;
  originName?: Maybe<Scalars["String"]>;
};

export type ProjectAccessInput = {
  workspaceId?: Maybe<Scalars["ID"]>;
  workspaceName?: Maybe<Scalars["String"]>;
  scenarioId?: Maybe<Scalars["ID"]>;
  scenarioName?: Maybe<Scalars["String"]>;
  originId?: Maybe<Scalars["ID"]>;
  originName?: Maybe<Scalars["String"]>;
};

export type ProjectFilter = {
  scenarioId?: Maybe<Scalars["ID"]>;
};

export type ProjectInput = {
  title?: Maybe<Scalars["String"]>;
  institutionId?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  access?: Maybe<ProjectAccessInput>;
};

export type ProjectResponse = {
  __typename?: "ProjectResponse";
  status: Status;
  project?: Maybe<Project>;
};

export enum ProjectState {
  /** Block by execution of background process */
  Block = "BLOCK",
  /** Ready for users to edit */
  Ready = "READY",
  Sync = "SYNC",
  Export = "EXPORT",
  Migrate = "MIGRATE",
}

export type Query = {
  __typename?: "Query";
  viewer?: Maybe<User>;
  projects?: Maybe<Array<Maybe<Project>>>;
  project?: Maybe<Project>;
  auth: Auth;
  status: Status;
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
  scenarios?: Maybe<Array<Maybe<Scenario>>>;
  origins?: Maybe<Array<Maybe<Origin>>>;
};

export type QueryProjectsArgs = {
  institutionId: Scalars["ID"];
  filter?: Maybe<ProjectInput>;
};

export type QueryProjectArgs = {
  projectId: Scalars["ID"];
};

export type QueryScenariosArgs = {
  workspaceId: Scalars["ID"];
};

export type QueryOriginsArgs = {
  scenarioId: Scalars["ID"];
};

export type Report = {
  __typename?: "Report";
  type: ReportType;
  url: Scalars["String"];
  projectId: Scalars["ID"];
};

export enum ReportType {
  DemandAdjustment = "DEMAND_ADJUSTMENT",
}

export enum ResultCode {
  Success = "SUCCESS",
  /** Demand in crosslist is empty */
  EmptyDemand = "EMPTY_DEMAND",
  /** Demand is already related to another crosslist */
  DemandAlreadyInCrosslist = "DEMAND_ALREADY_IN_CROSSLIST",
  /** The token is invalid (user not found) */
  InvalidToken = "INVALID_TOKEN",
  /** No project found with given id */
  InvalidProject = "INVALID_PROJECT",
  /** The given label is already related to another crosslist */
  CrosslistLabelAlreadyExits = "CROSSLIST_LABEL_ALREADY_EXITS",
  /** Demands deleted from units */
  PopDemands = "POP_DEMANDS",
  SuccessDeleted = "SUCCESS_DELETED",
  UserNotFound = "USER_NOT_FOUND",
  ErrorUpdateDemand = "ERROR_UPDATE_DEMAND",
  ErrorCreatingList = "ERROR_CREATING_LIST",
  ProjectMigrating = "PROJECT_MIGRATING",
  ProjectInSync = "PROJECT_IN_SYNC",
  ProjectExporting = "PROJECT_EXPORTING",
}

export type Role = {
  __typename?: "Role";
  id: Scalars["ID"];
  role?: Maybe<Scalars["String"]>;
  permissions?: Maybe<Scalars["JSON"]>;
};

export type Scenario = {
  __typename?: "Scenario";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  workspace?: Maybe<Workspace>;
};

export type Shift = {
  __typename?: "Shift";
  id: Scalars["ID"];
  name: Scalars["String"];
  code: Scalars["String"];
};

export type Status = {
  __typename?: "Status";
  code?: Maybe<Scalars["Int"]>;
  msg?: Maybe<ResultCode>;
};

export type SubjectViewResult = {
  __typename?: "SubjectViewResult";
  subject?: Maybe<Scalars["String"]>;
  unit?: Maybe<Unit>;
};

export type Subscription = {
  __typename?: "Subscription";
  unitUpdated: Unit;
  projectStateChange: ProjectState;
  migratedProject: Project;
  report: Report;
};

export type SubscriptionUnitUpdatedArgs = {
  projectId: Scalars["ID"];
};

export type SubscriptionProjectStateChangeArgs = {
  projectId: Scalars["ID"];
};

export type SubscriptionReportArgs = {
  projectId: Scalars["ID"];
  type: ReportType;
};

export type UnisabanaDepartment = {
  __typename?: "UnisabanaDepartment";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  code?: Maybe<Scalars["String"]>;
};

export type Unit = {
  __typename?: "Unit";
  id: Scalars["ID"];
  kind: UnitKind;
  label?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  progress?: Maybe<Scalars["Float"]>;
  totalDemand?: Maybe<Scalars["Int"]>;
  checkedCount?: Maybe<Scalars["Int"]>;
  totalProjected?: Maybe<Scalars["Int"]>;
  totalAdjusted?: Maybe<Scalars["Int"]>;
  demands: Array<Demand>;
  tags?: Maybe<Scalars["JSON"]>;
  programName?: Maybe<Scalars["String"]>;
  curriculumName?: Maybe<Scalars["String"]>;
  nextUnit?: Maybe<Scalars["ID"]>;
  previusUnit?: Maybe<Scalars["ID"]>;
  department?: Maybe<Department>;
};

export type UnitInput = {
  kind: Scalars["String"];
  label: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  projectId: Scalars["ID"];
  departmentId?: Maybe<Scalars["ID"]>;
  curriculumId?: Maybe<Scalars["ID"]>;
  shiftId?: Maybe<Scalars["ID"]>;
  demands: Array<Maybe<DemandUnitInput>>;
};

export enum UnitKind {
  Level = "LEVEL",
  Subject = "SUBJECT",
  Crosslist = "CROSSLIST",
  RuleCode = "RULE_CODE",
}

export type UnitResponse = {
  __typename?: "UnitResponse";
  unit?: Maybe<Unit>;
  status?: Maybe<Status>;
};

export type User = {
  __typename?: "User";
  id: Scalars["ID"];
  hash: Scalars["ID"];
  username?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  role?: Maybe<Role>;
  logoutUrl?: Maybe<Scalars["String"]>;
  institutionId?: Maybe<Scalars["String"]>;
};

export enum ValueState {
  /** The value wast not touched */
  Untouched = "UNTOUCHED",
  /** The value was touched */
  Touched = "TOUCHED",
  /** The value was not modified */
  Pristine = "PRISTINE",
  /** The value was modified */
  Adjusted = "ADJUSTED",
}

export type ViewFilter = {
  campusId?: Maybe<Scalars["ID"]>;
  departmentId?: Maybe<Scalars["ID"]>;
  shiftId?: Maybe<Scalars["ID"]>;
  modalityId?: Maybe<Scalars["ID"]>;
  programId?: Maybe<Scalars["ID"]>;
  curriculumId?: Maybe<Scalars["ID"]>;
  level?: Maybe<Scalars["Int"]>;
  unisabanaDepartmentId?: Maybe<Scalars["ID"]>;
};

export type Workspace = {
  __typename?: "Workspace";
  id: Scalars["ID"];
  code: Scalars["String"];
  name: Scalars["String"];
  institution?: Maybe<Institution>;
  scenarios?: Maybe<Array<Maybe<Scenario>>>;
};
